import React, { useState } from 'react'
import { fetchData } from '../../features/http/httpUtil'
import AdminNav from './AdminNav'

function SearchIndex() {
    const [complete, setComplete] = useState(false)
    const reindex = () =>
        fetchData(`/api/search/reindex`).then((result) => {
            setComplete(true)
        })

    return (
        <div className={`admin-content-pane`}>
            <AdminNav />
            <h1>Search Index</h1>
            <p>
                {complete === true ? (
                    <span>Re-index complete</span>
                ) : (
                    <input
                        type="button"
                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                        onClick={reindex}
                        value="Re-Index"
                    />
                )}
            </p>
        </div>
    )
}

export default SearchIndex
