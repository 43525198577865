import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAccessToken } from '../../features/http/httpUtil'
import { fetchAccountSecurityQuestion } from '../../features/account/accountSlice'
import { reauthenticateUser } from '../../features/auth/authSlice'

const SecurityQuestion = ({ userInfoObject }) => {
    const dispatch = useDispatch()

    
    useEffect(() => {
        dispatch(fetchAccountSecurityQuestion(userInfoObject.userID))
    }, [userInfoObject.userID]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        dispatch(reauthenticateUser())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const securityQuestion = useSelector(
        (state) => state.account.accountSecurityQuestion
    )

    if (!securityQuestion) {
        return <span>Security Question: Loading...</span>
    } else {
        return (
            <span>
                Security Question: {securityQuestion.question}{' '}
                <Link to={`/account/changeSecurity`}>Change</Link>
            </span>
        )
    }
}
const PinLocked = () => {
    return (
        <p>
            Your PIN has been locked. Please{' '}
            <Link to={`/contact/`}>contact</Link> the DEP Service Desk for
            assistance in unlocking it.
        </p>
    )
}

const PinVerified = () => {
    const authToken = getAccessToken()
    const contextRoot = window.__DEP_PORTAL_DATA__
        ? window.__DEP_PORTAL_DATA__.contextRoot || '/DepPortal'
        : '/DepPortal'
    return (
        <>
            <p>Your PIN has been verified and is ready for use.</p>

            <ul>
                <li>
                    If you have forgotten your PIN, you can{' '}
                    <Link to={`/pin/reset`}>reset</Link> it.
                </li>
                <li>
                    If you have feel that your PIN has been compromised,{' '}
                    <Link to={`/pin/reportCompromised`}>report it to us</Link>.
                </li>
                <li>
                    You can{' '}
                    <Link to={`/pin/changeSecurity`}>
                        change your PIN security questions
                    </Link>
                    .
                </li>

                
                <li>
                    You may view{' '}
                    <a href={`${contextRoot}/api/pin/view-pin-request?token=${authToken}`} target="_blank"> {/* eslint-disable-line react/jsx-no-target-blank */}
                        your PIN Request
                    </a>{' '}
                    here.
                </li>
            </ul>
        </>
    )
}

const PinApproved = () => {
    const contextRoot = window.__DEP_PORTAL_DATA__
        ? window.__DEP_PORTAL_DATA__.contextRoot || '/DepPortal'
        : '/DepPortal'
        const authToken = getAccessToken()
    return (
        <>
            <p>
                Your account has been approved for a PIN. You must{' '}
                <Link to={`/pin/verify`}>verify</Link> your PIN prior to use.
            </p>

            <p>
                If you did not receive the email with your PIN Request attached,
                you may view{' '}
                <a href={`${contextRoot}/api/pin/view-pin-request?token=${authToken}`} target="_blank"> {/* eslint-disable-line react/jsx-no-target-blank */}
                    your PIN Request
                </a>{' '}
                here.
            </p>
        </>
    )
}

const NoPin = () => {
    return (
        <p>
            Your account has not been approved for a PIN. If you need to
            electronically sign documents for DEP, you may{' '}
            <Link to={`/pin/request`}>apply for a PIN</Link>
        </p>
    )
}

const PinInformation = ({ userInfoObject }) => {
    if (!userInfoObject) {
        return <div>NO USER INFO OBJECT</div>
    } else if (!userInfoObject.pinContext) {
        return <div>NO PIN CONTEXT</div>
    }

    const p = userInfoObject.pinContext
    
    if (p.pinApproved === true) {
        if (p.pinLocked === true) {
            return PinLocked()
        } else if (p.pinVerified === true) {
            return PinVerified()
        } else {
            return PinApproved()
        }
    } else {
        return NoPin()
    }
}

const AccountDetails = () => {
    const userInfoObject = useSelector(
        (state) => state.auth.authResponse.userInfoObject
    )

    if (!userInfoObject) {
        return (
            <div className="content-pane">
                <h1>Your Account Details</h1>
                <p>Loading...</p>
            </div>
        )
    }
    return (
        <div className="content-pane">
            <h1>Your Account Details</h1>
            <div>
                E-mail: {userInfoObject.email}{' '}
                {userInfoObject.externalUser === true && (
                    <Link to={`/account/changeEmail`}>Update</Link>
                )}
            </div>
            <h2>Account Information</h2>
            <div>{userInfoObject.fullName}</div>
            <div>{userInfoObject.phoneNumber}</div>
            <div>{userInfoObject.addressLine1}</div>
            {userInfoObject.addressLine2 ? (
                <div>{userInfoObject.addressLine2}</div>
            ) : (
                <></>
            )}
            <div>
                {userInfoObject.city}, {userInfoObject.state}{' '}
                {userInfoObject.zipCode}
            </div>
            {userInfoObject.externalUser === true ? (
                <Link to={`/account/updateDetails`}>Update</Link>
            ) : (
                <></>
            )}
            <h2>Security Details</h2>
            <div>
                {userInfoObject.externalUser === true ? (
                    <>
                        Password: ********{' '}
                        <Link to={`/account/changePassword`}>
                            Change Password
                        </Link>
                    </>
                ) : (
                    'As an internal user, you must change your password through your Windows workstation.'
                )}
            </div>
            <div>
                {userInfoObject && userInfoObject.userID && (
                    <SecurityQuestion userInfoObject={userInfoObject} />
                )}
            </div>
            <h2>PIN</h2>
            {userInfoObject && userInfoObject.pinContext ? (
                <PinInformation userInfoObject={userInfoObject} />
            ) : (
                <div>N/A</div>
            )}
        </div>
    )
}

export default AccountDetails
