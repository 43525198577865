import React from 'react'
import { Link } from 'react-router-dom'
function Contact() {
    return (
        <div className="content-pane">
            <h1>Contact Us</h1>
            <p>
                If you are encountering difficulty using the DEP Business
                Portal, please contact our service desk at:
            </p>
            <table cellspacing="0" cellpadding="3">
                <tbody>
                    <tr>
                        <td>E-mail:</td>
                        <td>
                            <a href="mailto:servicedesk@dep.state.fl.us">
                                ServiceDesk@dep.state.fl.us
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Phone:</td>
                        <td>(850) 245-7555</td>
                    </tr>
                </tbody>
            </table>

            <h2>Account Security</h2>
            <p>
                If you believe that the security of your account has been
                compromised, you can{' '}
                <Link to="/account/reportCompromised">
                    report the compromise to us
                </Link>
                . We'll lock down your account and let you know how to recover
                it.
            </p>
        </div>
    )
}

export default Contact