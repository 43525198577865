import React from 'react'

import routes from './Routes'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom'

import { createBrowserHistory } from 'history'

import './App.css'

import SearchBox from './components/SearchBox'
import Breadcrumbs from './components/Breadcrumbs'
import ProtectedRoute from './components/ProtectedRoute'

const url = (uri) => `${uri}`

const AuthBar = () => {
    const authenticatedUser = useSelector((state) => state.auth.authResponse)
    return (
        <div id="authBar">
            {authenticatedUser && authenticatedUser.displayName ? (
                <span>
                    Welcome,{' '}
                    <Link to={`/account/details`}>
                        {' '}
                        {authenticatedUser.userInfoObject.fullName}
                    </Link>{' '}
                    [<Link to={`/account/signout`}>Sign Out</Link>]
                </span>
            ) : (
                <>
                    <span>
                        <Link to={`/account/register`}>Register</Link> | Already
                        have an account?{' '}
                    </span>
                    <span>
                        <Link to={`/account/signin`}>Sign In</Link>
                    </span>
                </>
            )}
        </div>
    )
}

function App() {


    const portlets = useSelector((state) => state.portlet.portlets)

    const mapPath = (chain) => {
        if(!portlets) {
            return []
        }

        let portlet = portlets[chain[0].uri]
        let parentId = portlet.parentId

        if(parentId) {
            let parent = Object.keys(portlets).map(k => portlets[k]).find(p => p.id === parentId)
            if(parent.uri === `home`) {
                return chain
            }
            let node = {id: parent.id, uri: parent.uri, name: parent.title, path: `/go/${parent.uri}`}
            let fullPath = [node, ...chain]
            
            return mapPath(fullPath)
        } else {
            return chain
        }

    }    



    const contextRoot = window.__DEP_PORTAL_DATA__
        ? window.__DEP_PORTAL_DATA__.contextRoot || '/DepPortal'
        : '/DepPortal'

    return (
        <Router basename={`${contextRoot}`}>
            <div id="banner" role="banner">
                <div id="header" role="heading">
                    <div id="head" style={{ height: '94px' }}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.floridadep.gov"
                        >
                            <img
                                alt="Florida Department of Environmental Protection Logo"
                                id="logo"
                                border="0"
                                height="80"
                                width="80"
                                src={url(
                                    `${contextRoot}/images/logo-80x80.png`
                                )}
                            />
                        </a>
                        <div className="dbp">Business Portal</div>
                        <div id="subhead">
                            <div className="pageTitle">DEP Portal</div>
                            <div className="pageSubTitle">
                                Enterprise Solutions
                            </div>
                        </div>
                    </div>
                </div>
                <AuthBar />
            </div>
            <div className="clear"></div>
            <div id="main" role="main">
                <div id="searchBar">
                    <SearchBox />
                </div>

                <Switch>
                    {routes.map(({path, name, protectedRoute, exact, Component}, key) => {
                            const RouteComponent = protectedRoute
                                ? ProtectedRoute
                                : Route
                            return (
                                <RouteComponent
                                    exact={exact}
                                    path={path}
                                    key={key}
                                    render={props => {
                                        const currentUri = props.match.params.uri
                                        
                                         const crumbs = portlets
                                         && portlets.home
                                         && currentUri !== `home`
                                         && portlets[currentUri]
                                         && props.match.path.includes(routes[0].path) ?
                                                  mapPath([{id: portlets[currentUri].id, uri: currentUri, name: portlets[currentUri].title, path: `/go/${currentUri}`}])
                                        :
                                        
                                         routes
                                            .filter(({ path, crumbable = true}) =>  crumbable && props.match.path.startsWith(path))
                                            .map(({ path, ...rest }) => ({
                                                path: Object.keys(props.match.params).length
                                                    ? Object.keys(props.match.params)
                                                    .reduce((path, param) => path.replace(`:${param}`, props.match.params[param]), path)
                                                    : path,
                                                ...rest,
                                            }))

                                        return (
                                            <>
                                                <Breadcrumbs crumbs={crumbs} />
                                                <div className="clear"></div>
                                                <Component {...props} />
                                            </>
                                        )
                                    }}
                                />
                            )
                        }
                    )}
                </Switch>
            </div>
            <div className="clear"></div>
            <footer>
                <div className={`footer-links`}>
                    <a href="https://www.floridadep.gov" target="_new">
                        DEP Home
                    </a>
                    {' | '}
                    <a href="https://floridadep.gov/about-dep" target="_new">
                        About DEP
                    </a>
                    {' | '}
                    <Link to={`/contact/`}>Contact Us</Link>
                    {' | '}
                    <Link to={`/sitemap`}>Site Map</Link>
                </div>
                <div className={`footer-info`}>
                    <span className={`version-info`}>Application version {window.__DEP_PORTAL_DATA__
        ? window.__DEP_PORTAL_DATA__.appVersion: `Unknown`}</span>
                </div>
            </footer>
        </Router>
    )
}
export const history = createBrowserHistory({
    basename: window.__DEP_PORTAL_DATA__
        ? window.__DEP_PORTAL_DATA__.contextRoot || '/DepPortal'
        : process.env.PUBLIC_URL || '/DepPortal',
})
export default App
