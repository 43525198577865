import React, { useState, useEffect } from 'react'
import { postData } from '../../features/http/httpUtil'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { decode } from 'jsonwebtoken'

const Credentials = ({ updateCredentials }) => {
    const [identifier, setIdentifier] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        updateCredentials(identifier, password)
    }, [identifier, password]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <tr>
                <td>&nbsp;</td>
                <td>
                    <span className="italic12pt">
                        (e.g., wile.e.coyote@domain.com OR coyote_we)
                    </span>
                </td>
            </tr>
            <tr>
                <td className="tdLabel">
                    <label htmlFor="username" className="label">
                        E-mail Address
                        <span className="required">*</span>:
                    </label>
                </td>
                <td>
                    <input
                        type="text"
                        name="username"
                        size="40"
                        maxLength="255"
                        id="username"
                        autoFocus="autofocus"
                        onChange={(e) => setIdentifier(e.target.value)}
                        value={identifier}
                    />
                </td>
            </tr>
            <tr>
                <td className="tdLabel">
                    <label htmlFor="password" className="label">
                        Password
                        <span className="required">*</span>:
                    </label>
                </td>
                <td>
                    <input
                        type="password"
                        name="password"
                        size="20"
                        maxLength="30"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                </td>
            </tr>
        </>
    )
}
function PinSignature() {
    const location = useLocation()

    const { token } = queryString.parse(location.search)


    const [identifier, setIdentifier] = useState('')
    const [password, setPassword] = useState('')

    const [question, setQuestion] = useState('')
    const [questionId, setQuestionId] = useState('')
    const [answer, setAnswer] = useState('')
    const [requireCredentials, setRequireCredentials] = useState(false)
    const [pin, setPin] = useState('')

    //const [, setStatus] = useState('')
    const [error, setError] = useState('')
    

    const decodedSerializedToken = token && decode(token).entity
    
    const decodedToken = decodedSerializedToken && JSON.parse(decodedSerializedToken)
    

    useEffect(() => {
        if (decodedToken && decodedToken.question) {
            setQuestionId(decodedToken.questionId)
            setQuestion(decodedToken.question)
            setRequireCredentials(decodedToken.requireCredentials)
        }
    }, [decodedToken])
    if (!token) {
        return 'Invalid request: token required.'
    }
    const handleCredentials = (id, pw) => {
        setIdentifier(id)
        setPassword(pw)
    }
    const handleForm = (e) => {
        e.preventDefault()
        const sig = {
            identifier: identifier,
            password: password,
            questionAnswerPair: {
                securityQuestionId: questionId,
                securityAnswer: answer,
            },
            pin: pin,
            token: token,
        }
        postData(`/api/pin/sign`, { ...sig }).then((result) => {
            if (result.error || !result.redirectUrl) {
                if (result.error) {
                    setError(result.error)
                } else {
                    setError('')
                }
            } else {
                setError('')
                window.location.replace(result.redirectUrl)
            }
        })
    }

    const cancelSignature = (e) => {
        e.preventDefault()
    }

    return (
        <div className="content-pane">
            <h1>Electronically Sign</h1>

            <p>
                I understand that I shall be held as legally bound, obligated
                and responsible by the electronic signature created using this
                electronic signature device as by my handwritten signature.
                Additionally, I certify that I have not violated any terms in my
                Electronic Signature Agreement and that I have no reason to
                believe that the confidentiality of my electronic signature
                device and security question/answer pairs have been compromised
                now or at any time prior to this submission.
            </p>
            {error && <span className="errorMessage">{error}</span>}
            <form autoComplete="off" onSubmit={handleForm}>
                <table className="formTable">
                    <tbody>
                        {requireCredentials && (
                            <Credentials
                                updateCredentials={handleCredentials}
                            />
                        )}

                        <tr>
                            <td className="tdLabel">
                                <label
                                    htmlFor="securityAnswer"
                                    className="label"
                                >
                                    {question}
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    name="securityAnswer"
                                    type="text"
                                    id="securityAnswer"
                                    size="30"
                                    maxLength="50"
                                    required={true}
                                    onChange={(e) => {
                                        setAnswer(e.target.value)
                                    }}
                                    value={answer}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="pin" className="label">
                                    PIN<span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    name="pin"
                                    type="number"
                                    id="pin"
                                    size="4"
                                    maxLength="4"
                                    min="1000"
                                    max="9999"
                                    value={pin}
                                    onChange={(e) => {
                                        setPin(e.target.value)
                                    }}
                                    required={true}
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">
                                <input
                                    type="submit"
                                    className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                    value="Sign"
                                    onClick={handleForm}
                                />

                                <input
                                    type="button"
                                    className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                    onClick={cancelSignature}
                                    value="Cancel"
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </form>
        </div>
    )
}

export default PinSignature
