import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authenticateUser } from '../../features/auth/authSlice'
import queryString from 'query-string'
import { useLocation, Redirect, Link } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import { defaultApiUrl } from '../../features/http/httpUtil'

function SignIn() {
    const location = useLocation()
    const {
        internalRedirect,
        redirect,
        appName,
        useJWT, // eslint-disable-line no-unused-vars
        apiRequest, // eslint-disable-line no-unused-vars
    } = queryString.parse(location.search)

    const message = useSelector((state) => state.auth.authResponse.message)
    const status = useSelector((state) => state.auth.authResponse.status)
    const accessToken = useSelector((state) => state.auth.authResponse.accessToken)
    const rememberMeCookieToken = useSelector((state) => state.auth.authResponse.rememberMeCookieToken)

    const [identifier, setIdentifier] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()

    const handleForm = (event) => {
        event.preventDefault()
        dispatch(authenticateUser({ identifier, password, appName }))
    }

    if (status === `success`) {
        if (redirect) {
            //const host = window.location.hostname
            //window.location.replace(`//${host}${defaultApiUrl}/api/authorized-redirect?remember-me-token=${rememberMeCookieToken}&redirect-target=${redirect}`)
            window.location.replace(`${defaultApiUrl}/authorized-redirect?remember-me-token=${rememberMeCookieToken}&redirect-target=${redirect}`)
        } else {
            let dest = internalRedirect || '/go/home'
            return <Redirect to={dest} />
        }
    }

    return (
        <div className="content-pane">
            {accessToken ? (
                <>
                    <h1>Signing in...</h1>
                    <Spinner />
                </>
            ) : (
                <>
                    <h1>Sign In</h1>
                    <form onSubmit={handleForm} autoComplete="off">
                        <p>
                            If you've already registered with the DEP sign in
                            below. DEP employees may use their network username
                            to sign-in.
                        </p>
                        <table className="formTable">
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>
                                        <span className="italic12pt">
                                            (e.g., wile.e.coyote@domain.com OR
                                            coyote_we)
                                        </span>
                                    </td>
                                </tr>

                                {message && status !== `success` && (
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <span
                                                className="errorMessage"
                                                id="errorMessage"
                                            >
                                                {message}
                                            </span>
                                        </td>
                                    </tr>
                                )}

                                {message && status === `success` && (
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <span
                                                className="message"
                                                id="message"
                                            >
                                                {message}
                                                <Spinner />
                                            </span>
                                        </td>
                                    </tr>
                                )}

                                {status !== `success` && (
                                    <>
                                        <tr>
                                            <td className="tdLabel">
                                                <label
                                                    htmlFor="username"
                                                    className="label"
                                                >
                                                    E-mail Address
                                                    <span className="required">
                                                        *
                                                    </span>
                                                    :
                                                </label>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="username"
                                                    size="40"
                                                    maxLength="255"
                                                    required
                                                    id="username"
                                                    autoFocus="autofocus"
                                                    onChange={(e) =>
                                                        setIdentifier(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={identifier}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="tdLabel">
                                                <label
                                                    htmlFor="password"
                                                    className="label"
                                                >
                                                    Password
                                                    <span className="required">
                                                        *
                                                    </span>
                                                    :
                                                </label>
                                            </td>
                                            <td>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    size="20"
                                                    maxLength="30"
                                                    required
                                                    id="password"
                                                    onChange={(e) =>
                                                        setPassword(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={password}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>
                                                <Link to="/account/resetPassword">
                                                    I forgot my password.
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <div align="center">
                                                    <input
                                                        type="submit"
                                                        id="signInButton"
                                                        value="Sign In"
                                                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                        <span>
                            If not, then{' '}
                            <Link to="/account/register">register</Link>.
                        </span>
                    </form>
                </>
            )}
        </div>
    )
}

export default SignIn
