import React, { useState, useEffect } from 'react'
import { putData, useGet } from '../../features/http/httpUtil'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import {
    InputRow,
    InputTel,
    validate,
    hasError,
    InputSelect,
} from '../../components/forms'
import { useLocation, Redirect } from 'react-router-dom'

const IncompleteProfile = () => {
    const accessToken = useSelector(
        (state) => state.auth.authResponse.accessToken
    )
    const location = useLocation()
    /* eslint-disable */
    const {
        internalRedirect,
        redirect,
    } = queryString.parse(location.search)
    /* eslint-enable */

    const authenticatedUser = useSelector((state) => state.auth.authResponse)

    const isExternal =
        authenticatedUser && authenticatedUser.userInfoObject.externalUser

    const accountDetailsMetadata = {
        fields: {
            firstName: {
                title: 'First Name',
                required: true,
                validated: isExternal,
                size: 20,
                maxLength: 50,
            },
            middleName: {
                title: 'Middle Name',
                required: false,
                validated: isExternal,
                size: 20,
                maxLength: 50,
            },
            lastName: {
                title: 'Last Name',
                required: true,
                validated: isExternal,
                size: 20,
                maxLength: 50,
            },
            addressLine1: {
                title: 'Address (Line 1)',
                required: true,
                validated: isExternal,
                size: 40,
                maxLength: 50,
            },
            addressLine2: {
                title: 'Address (Line 2)',
                required: false,
                validated: isExternal,
                size: 40,
                maxLength: 50,
            },
            city: {
                title: 'City',
                required: true,
                validated: isExternal,
                size: 30,
                maxLength: 50,
            },
            state: {
                title: 'State',
                required: true,
                validated: isExternal,
                size: 2,
                maxLength: 2,
            },
            postalCode: {
                title: 'Zip Code',
                required: true,
                validated: isExternal,
                size: 10,
                maxLength: 5,
            },
            telephoneNumber: {
                title: 'Phone Number',
                required: true,
                validated: isExternal,
                size: 30,
                maxLength: 14,
            },
            securityQuestion: {
                title: 'Security Question',
                required: true,
                validated: true,
                size: 1,
            },
            securityAnswer: {
                title: 'Security Answer',
                required: true,
                validated: true,
                size: 35,
                maxLength: 50,
            },
        },
    }

    const {
        data: accountDetails,
        pending: accountDetailsLoading,
    } = useGet(`/api/accounts/details`)

    const [state, setState] = useState({
        data: {},
        errors: {},
        meta: accountDetailsMetadata,
    })

    const { data: secQuestions, pending: secQuestionsLoading } = useGet(
        `/api/security-questions/valid-questions/`
    )

    const { data, errors, meta } = state
    const formProps = { state, setState }

    const [finished, setFinished] = useState()

    useEffect(() => {
        setState({
            data: {
                ...accountDetails,
                securityQuestion: secQuestions ? secQuestions[0].id : 0,
            },
            errors: {},
            meta: accountDetailsMetadata,
        })
        return () => {}
    }, [accountDetailsLoading, secQuestionsLoading]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateIncompleteProfile = (e) => {
        e.preventDefault()
        const formErrors = validate(data, meta)
        if (hasError(formErrors)) {
            setState({ ...state, errors: formErrors })
        } else {
            putData(`/api/accounts/update-incomplete-profile`, {
                ...data,
                uid: authenticatedUser.uid,
            }).then((result) => {
                if (result.status && result.status !== 200) {
                    let serverValidationErrors = {}
                    result.errors.forEach(function (r) {
                        serverValidationErrors[r.field] = r.defaultMessage
                    })

                    setState({
                        ...state,
                        errors: {
                            ...state.errors,
                            ...serverValidationErrors,
                            saveError: result,
                        },
                    })
                } else {
                    setFinished(true)
                }
            })
        }
    }

    if (accountDetailsLoading || !accountDetails || secQuestionsLoading) {
        return `Loading...`
    } else if (finished === true) {
        if (redirect) {
            let decodedURI = decodeURIComponent(redirect)
            window.location.replace(
                decodedURI +
                    (decodedURI.indexOf('?') > -1 ? '&' : '?') +
                    `portal_access_token=${accessToken}`
            )
        } else {
            const dest = internalRedirect || '/go/home'
            return <Redirect to={dest} />
        }
    }

    return (
        <div id="updateIncompleteProfile" className="content-pane">
            <h1>Update Incomplete Profile</h1>

            <form onSubmit={updateIncompleteProfile}>
                <table>
                    <tbody>
                        {errors && errors.saveError && (
                            <tr>
                                <td colSpan="2">
                                    <span
                                        className="errorMessage"
                                        id="errorMessage"
                                    >
                                        There was a problem with your request
                                    </span>
                                    <br />
                                </td>
                            </tr>
                        )}

                        {authenticatedUser.userInfoObject.externalUser && (
                            <>
                                <InputRow name="firstName" {...formProps} />

                                <InputRow name="middleName" {...formProps} />

                                <InputRow name="lastName" {...formProps} />

                                <InputRow name="addressLine1" {...formProps} />

                                <InputRow name="addressLine2" {...formProps} />

                                <InputRow name="city" {...formProps} />

                                <InputRow name="state" {...formProps} />

                                <InputRow name="postalCode" {...formProps} />

                                <InputRow
                                    name="telephoneNumber"
                                    {...formProps}
                                    InputComponent={InputTel}
                                />
                            </>
                        )}

                        <InputRow
                            name="securityQuestion"
                            {...formProps}
                            InputComponent={InputSelect}
                        >
                            {secQuestions.map((element) => (
                                <option key={element.id} value={element.id}>
                                    {element.question}
                                </option>
                            ))}
                        </InputRow>
                        <InputRow name="securityAnswer" {...formProps} />

                        <tr>
                            <td colSpan="2" align="right">
                                <input
                                    type="submit"
                                    value="Update Account Information"
                                    className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default IncompleteProfile
