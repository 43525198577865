import React, { useState, useEffect } from 'react'
import { putData, useGet } from '../../features/http/httpUtil'

function ChangeSecurityQuestion() {
    const [securityQuestion, setSecurityQuestion] = useState()
    const [newSecurityAnswer, setNewSecurityAnswer] = useState('')
    const [message, setMessage] = useState(null)
    const [error, setError] = useState(null)
    let { data: secQuestions, pending: secQuestionsLoading } = useGet(
        `/api/security-questions/valid-questions/`
    )

    const updateSecurityAnswer = () => {
        return putData('/api/security-questions/account-answer', {
            securityQuestionId: securityQuestion,
            securityAnswer: newSecurityAnswer,
        })
    }

    const handleForm = (event) => {
        event.preventDefault()
        updateSecurityAnswer().then((r) => {
            if (r && r.message === 'success') {
                setError(null)
                setMessage(
                    'Your security question and answer have been updated.'
                )
            } else {
                setError('An error occurred.')
                setMessage(null)
            }
        })
    }

    useEffect(() => {
        if (secQuestions && secQuestions[0]) {
            setSecurityQuestion(secQuestions[0].id)
        }
    }, [secQuestions])

    if (secQuestionsLoading) {
        return <p>Loading...</p>
    }

    return (
        <div className="content-pane">
            <h1>Change Account Security</h1>

            <form onSubmit={handleForm}>
                <table className="formTable">
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                {error && (
                                    <span
                                        className="errorMessage"
                                        id="errorMessage"
                                    >
                                        {error}
                                    </span>
                                )}
                                {message && (
                                    <span
                                        className="successMessage"
                                        id="successMessage"
                                    >
                                        {message}
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label
                                    htmlFor="securityQuestion"
                                    className="label"
                                >
                                    Security Question
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <select
                                    name="securityQuestion"
                                    id="securityQuestion"
                                    onChange={(e) =>
                                        setSecurityQuestion(e.target.value)
                                    }
                                    autoFocus={true}
                                    value={securityQuestion}
                                >
                                    {secQuestions.map((element) => (
                                        <option
                                            key={element.id}
                                            value={element.id}
                                        >
                                            {element.question}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td className="tdLabel">
                                <label
                                    htmlFor="securityAnswer"
                                    className="label"
                                >
                                    Security Answer
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    name="securityAnswer"
                                    id="securityAnswer"
                                    type="text"
                                    required={true}
                                    onChange={(e) =>
                                        setNewSecurityAnswer(e.target.value)
                                    }
                                    value={newSecurityAnswer}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="2">
                                <div align="center">
                                    <input
                                        type="submit"
                                        id=""
                                        value="Update Security"
                                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default ChangeSecurityQuestion
