import queryString from 'query-string'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { fetchData } from '../../features/http/httpUtil'


function VerifyChangeEmail() {
    const location = useLocation()
    const { confirmationId } = queryString.parse(location.search)

    if (confirmationId) {
        fetchData(
            `/api/accounts/complete-email-change?confirmationId=${confirmationId}`
        ).then((result) => {
            
        })
    }



    return (
        <div className="content-pane">
            <h1>E-mail Changed</h1>

            <p>Your email address has been updated.</p>
        </div>
    )
}

export default VerifyChangeEmail
