import React from 'react'

import queryString from 'query-string'
import { useLocation, Link } from 'react-router-dom'
import Spinner from '../components/Spinner'
import { useGet } from '../features/http/httpUtil'

function Pagination({ beginIndex, itemCount, pageSize, query }) {
    const pageCount = Math.ceil(itemCount / pageSize)
    const currentPage = 1 + beginIndex / pageSize
    const pages = [...Array(pageCount).keys()]
    return (
        <div className="pagination">
            Page{` `}
            {[...pages].map((page) => (
                <span key={page + 1}>
                    {page + 1 !== currentPage ? (
                        <>
                            {` `}
                            <Link
                                to={`/search?query=${query}&beginIndex=${
                                    page * pageSize
                                }&endIndex=${(page + 1) * pageSize}`}
                            >
                                {page + 1}
                            </Link>
                            {` `}
                        </>
                    ) : (
                        <span style={{ fontWeight: 'bold' }}>
                            {` `}
                            {page + 1}
                            {` `}
                        </span>
                    )}
                </span>
            ))}
        </div>
    )
}
function SearchResult({ result }) {
    return (
        <div className="searchResult">
            <span className="title">
                <a href={result.url}>{result.title}</a>
            </span>
            <p className="description">{result.description}</p>
            <cite>{result.citation}</cite>
        </div>
    )
}

function SearchResults() {
    const location = useLocation()
    const pageSize = 20
    const { query, beginIndex, endIndex } = queryString.parse(location.search)

    const fromIndex = beginIndex ? parseInt(beginIndex) + 1 : 1

    const { data: response, pending: searchResultsLoading } = useGet(
        `/api/search?query=${query}&begin-index=${
            beginIndex || '0'
        }&end-index=${endIndex || pageSize}`
    )

    return (
        <div className="content-pane">
            <h1>Search Results</h1>
            <p>
                Tip: You can use an asterisk (*) in the middle or at the end of
                your search phrase to find partial matches.
            </p>
            {searchResultsLoading && <Spinner />}
            {response && (
                <>
                    {response.size > 0 && (
                        <>
                            <p>
                                {`There ${
                                    response.size === 1
                                        ? `was 1 result`
                                        : `were ${response.size} results`
                                } found for "${query}". Displaying results ${fromIndex} - ${
                                    fromIndex +
                                    (response.searchResults.length - 1)
                                } of ${response.size}.`}
                            </p>
                            {response.searchResults.map((result) => (
                                <SearchResult
                                    key={result.key}
                                    result={result}
                                />
                            ))}
                            <Pagination
                                beginIndex={beginIndex || 0}
                                itemCount={response.size}
                                pageSize={pageSize}
                                query={query}
                            />
                        </>
                    )}
                    {response.size === 0 && (
                        <p>There were no results found for "{query}".</p>
                    )}
                </>
            )}
        </div>
    )
}

export default SearchResults
