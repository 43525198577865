import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { SecurityQuestionAnswerRow } from '../../components/SecurityQuestionAnswerRow'
import { fetchSecurityQuestions } from '../../features/account/accountSlice'
import { postData } from '../../features/http/httpUtil'

const ValidationMessage = ({ message }) => {
    return <li>{message}</li>
}
const ValidationMessages = ({ messages }) => {
    return (
        <ul>
            {messages &&
                messages.map((message, index) => (
                    <ValidationMessage key={index} message={message} />
                ))}
        </ul>
    )
}
function PinRequest() {
    const dispatch = useDispatch()

    const userInfoObject = useSelector(
        (state) => state.auth.authResponse.userInfoObject
    )

    const [state, setState] = useState({
        data: { pairs: [{}, {}, {}, {}, {}] },
        status: null,
        errors: null,
    })

    const { data, status, errors } = state
    const errorMessages =
        errors && errors.length > 0
            ? errors.map((error) => {
                  return error.defaultMessage
              })
            : []


    const availableQuestions = useSelector(
        (state) => state.account.securityQuestions
    )

    const availableQuestionsLoaded =
        availableQuestions && availableQuestions.length > 0

    useEffect(() => {
        dispatch(fetchSecurityQuestions())
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePairChange = (e) => {
        const newData = { ...data }
        newData.pairs[e.target.dataset.idx][e.target.className] = e.target.value
        setState({ data: newData, errors: errors })
    }

    const handleForm = (e) => {
        e.preventDefault()
        const request = { questionAnswerPairs: data.pairs }
        postData(`/api/pin/request-pin`, { ...request }).then((result) => {
            if (result.status && result.status !== 200) {
                setState({ ...state, status: 'error', errors: result.errors })
            } else {
                setState({ ...state, status: 'complete', errors: null })
            }
        })
    }

    if (status === 'complete') {
        return (
            <div className="content-pane">
                <h1>PIN Request</h1>
                <p>
                    We have sent an email to{' '}
                    <strong>{userInfoObject.email}</strong> with a form which
                    must be notarized and mailed to us. Full instructions are
                    included in the email.
                </p>
            </div>
        )
    }
    if (!availableQuestionsLoaded) {
        return <p>Loading...</p>
    } else if (
        userInfoObject &&
        userInfoObject.pinContext &&
        userInfoObject.pinContext.pinApproved === true
    ) {
        return <Redirect to={`/account/details`} />
    } else {
        return (
            <div className="content-pane">
                <h1>PIN Request</h1>

                <p>
                    In order to electronically sign documents submitted to DEP,
                    you must obtain a PIN. To apply for a PIN, please follow the
                    instructions below.
                </p>

                <h2>Verify Account Information</h2>
                <div>{userInfoObject.fullName}</div>
                <div>{userInfoObject.phoneNumber}</div>
                <div>{userInfoObject.addressLine1}</div>
                {userInfoObject.addressLine2 ? (
                    <div>{userInfoObject.addressLine2}</div>
                ) : (
                    ''
                )}
                <div>
                    {userInfoObject.city}, {userInfoObject.state}{' '}
                    {userInfoObject.zipCode}
                </div>

                <p>
                    Please make sure your account information is up to date
                    before continuing.
                    <Link to={`/account/updateDetails?forPin=true`}>
                        Update Account Information
                    </Link>
                </p>

                <form onSubmit={handleForm} id="request" name="request">
                    <h2>PIN Security</h2>
                    <p>
                        From the list provided, please select five personal
                        security questions that:
                    </p>
                    <ol>
                        <li>cannot be easily guessed or researched</li>
                        <li>don't change over time</li>
                        <li>
                            are memorable to you (i.e., others in your work
                            place would not be aware of or familiar with based
                            on what they know about you)
                        </li>
                        <li>are definitive or simple</li>
                    </ol>
                    <p>
                        These must be different from your account security
                        question.
                    </p>

                    <span className="errorMessage" id="errorMessage">
                        <ValidationMessages messages={errorMessages} />
                    </span>
                    <br />

                    <div>
                        {[...Array(5)].map((x, index) => (
                            <SecurityQuestionAnswerRow
                                key={index}
                                index={index}
                                availableQuestions={availableQuestions}
                                title={`Security Question ${index + 1}`}
                                pairStates={data.pairs}
                                handlePairChange={handlePairChange}
                            />
                        ))}
                    </div>

                    <div className="inline-center">
                        <input
                            type="submit"
                            className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                            value="Generate PIN Application"
                            aria-disabled="false"
                        />
                    </div>
                </form>
            </div>
        )
    }
}

export default PinRequest
