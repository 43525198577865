import React from 'react'
import { Link } from 'react-router-dom'
const Breadcrumbs = ({ crumbs }) => {
    const elements = [<Link key={`homecrumb`} to={`/go/home`}>Home</Link>, ...crumbs.map(({ name, path }, key) => 
        
            <Link key={key} to={path}>
                {name}
                
            </Link>
        
    )]
    return (
        <div className={`crumb-bar-wrapper`}>
            <div className={`crumb-bar`}>
                {elements.reduce((prev, curr) => [prev, <span key={`span${curr.key}`}>{` | `}</span>, curr])}
            </div>
        </div>
    )
}
export default Breadcrumbs
