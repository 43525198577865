import React, { useState } from 'react'
import { fetchData } from '../../features/http/httpUtil'

function ResendEmailVerification() {
    const [emailAddress, setEmailAddress] = useState('')
    const [error, setError] = useState('')

    const handleForm = (event) => {
        event.preventDefault()
        fetchData(
            `/api/accounts/resend-email-verification/${emailAddress}`
        ).then((result) => {
            if (result.depEmployee) {
                setError(
                    'This feature is not available to internal users. Please contact the DEP help desk for assistance.'
                )
                return
            }
            if (result.notRegistered) {
                setError(
                    'The specified e-mail address has not been registered.'
                )
                return
            }
            if (result.success) {
                setError("We've resent your verification email.")
            }
        })
    }

    return (
        <div className="content-pane">
            <h1>Resend Verification E-mail</h1>
            <p>Can't find the e-mail that we sent to you? Let's try again.</p>
            <p>
                We can resend our e-mail to you to complete the registration.
                Just enter your e-mail address, click the "Resend Verification
                E-mail" button, and check your inbox again. It may take a few
                moments for the e-mail to arrive.
            </p>

            <form onSubmit={handleForm}>
                <table className="formTable">
                    <tr>
                        <td colSpan="2">
                            <span className="errorMessage" id="errorMessage">
                                {error}
                            </span>
                            <br />
                        </td>
                    </tr>
                    <tr>
                        <td className="tdLabel">
                            <label htmlFor="email" className="label">
                                E-mail Address
                                <span className="required">*</span>:
                            </label>
                        </td>
                        <td>
                            <input
                                type="email"
                                name="email"
                                size="40"
                                maxLength="255"
                                id="email"
                                autoFocus="autofocus"
                                onChange={(e) =>
                                    setEmailAddress(e.target.value)
                                }
                                value={emailAddress}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <div align="center">
                                <input
                                    type="submit"
                                    value="Resend Verification E-mail"
                                    className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                />
                            </div>
                        </td>
                    </tr>
                </table>
            </form>
        </div>
    )
}

export default ResendEmailVerification
