import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { postData } from '../../features/http/httpUtil'
import { signOut } from '../../features/auth/authSlice'

function ChangePassword() {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    const dispatch = useDispatch()

    const handleForm = event => {
        event.preventDefault()
        submitPasswordChanges()
    }

    function submitPasswordChanges() {
        if (newPassword !== confirmPassword) {
            setError("New and confirm password do not match.")
            return
        }
        postData('/api/accounts/change-password', {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        }).then(result => {
            if (!result.passwordCorrect) {
                setError('Password is incorrect')
            }
            if (result.success) {
                //setError('Password successfully changed.')
                dispatch(signOut(`/account/signin`))
            }
        })
    }

    return (
        <div className="content-pane">
            <h1>Change Password</h1>
            <p>
                Passwords must be between 8 and 20 characters long and must
                contain at least one uppercase letter, one lowercase letter, and
                one number.
            </p>
            <form onSubmit={handleForm}>
                <table className="formTable">
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <span
                                    className="errorMessage"
                                    id="errorMessage"
                                >
                                    {error}
                                </span>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="oldPassword" className="label">
                                    Existing Password
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="password"
                                    name="oldPassword"
                                    size="20"
                                    maxLength="30"
                                    id="oldPassword"
                                    required
                                    autoFocus="autofocus"
                                    onChange={e =>
                                        setOldPassword(e.target.value)
                                    }
                                    value={oldPassword}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="newPassword" className="label">
                                    New Password
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="password"
                                    name="newPassword"
                                    size="20"
                                    maxLength="30"
                                    id="newPassword"
                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    required
                                    onChange={e =>
                                        setNewPassword(e.target.value)
                                    }
                                    value={newPassword}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label
                                    htmlFor="confirmPassword"
                                    className="label"
                                >
                                    Confirm Password
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    size="20"
                                    maxLength="30"
                                    id="confirmPassword"
                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    required
                                    onChange={e =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    value={confirmPassword}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <div align="center">
                                    <input
                                        type="submit"
                                        id=""
                                        value="Change Password"
                                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                        aria-disabled="false"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default ChangePassword
