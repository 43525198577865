import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { putData } from '../../features/http/httpUtil'

function ErrorSpan({ children }) {
    return (
        <>
            <br />
            <span className="errorMessage">{children}</span>
        </>
    )
}
const MustEqual = ({ message }) => (
    <ErrorSpan>{message || `E-mails addresses must match.`}</ErrorSpan>
)

function ChangeEmail() {
    const { register, handleSubmit, watch, errors, setError } = useForm() // eslint-disable-line no-unused-vars
    const [emailSent, setEmailSent] = useState(false)
    const [message, setMessage] = useState('')

    const onSubmit = (data) => {
        if (data.email === data.confirmEmail) {
            putData(`/api/accounts/change-email`, data).then((result) => {
                if(result.result !== 'success') {
                    setMessage(`An error occurred.`)
                } else {
                    setMessage('')
                    // TODO: check for errors, right now assuming everything worked
                    setEmailSent(true)
                }
            })
        } else {
            setError(`confirmEmail`, `E-mail addresses must match.`)
        }
    }

    return (
        <>
            <div className="content-pane">
                <h1>Change E-mail</h1>
                {message && (<p><span className="errorMessage">{message}</span></p>)}
                {emailSent ? (
                    <p>
                        An email has been sent to you to verify your new email
                        address.
                    </p>
                ) : (
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <table className="formTable">
                            <tbody>
                                <tr>
                                    <td className="tdLabel">
                                        <label
                                            htmlFor="email"
                                            className="label"
                                        >
                                            E-mail Address
                                            <span className="required">*</span>:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            type="email"
                                            name="email"
                                            size="40"
                                            id="email"
                                            autoFocus="autofocus"
                                            ref={register({
                                                maxLength: 255,
                                                required: true,
                                            })}
                                        />
                                        {errors.email && <MustEqual message="E-mail addresses must match." />}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="tdLabel">
                                        <label
                                            htmlFor="confirmEmail"
                                            className="label"
                                        >
                                            Confirm E-mail Address
                                            <span className="required">*</span>:
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            type="email"
                                            name="confirmEmail"
                                            size="40"
                                            id="confirmEmail"
                                            ref={register({
                                                maxLength: 255,
                                                required: true,
                                            })}
                                        />
                                        {errors.confirmEmail && <MustEqual message="E-mail addresses must match." />}
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        <div align="center">
                                            <input
                                                type="submit"
                                                value="Change E-mail"
                                                className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                )}
            </div>
        </>
    )
}

export default ChangeEmail
