import React from 'react'
import SignIn from './pages/account/SignIn'
import { Redirect } from 'react-router-dom'
import { HomePageInfo, MainPortletArea } from './pages/home/Home'
import IncompleteProfile from './pages/account/IncompleteProfile'
import SignOut from './pages/account/SignOut'
import ResetPassword from './pages/account/ResetPassword'
import SiteMap from './pages/SiteMap'
import VerifyResetPassword from './pages/account/VerifyResetPassword'
import AccountDetails from './pages/account/AccountDetails'
import ChangeSecurityQuestion from './pages/account/ChangeSecurityQuestion'
import ChangePassword from './pages/account/ChangePassword'
import UpdateAccountDetails from './pages/account/UpdateAccountDetails'
import ChangeEmail from './pages/account/ChangeEmail'
import VerifyChangeEmail from './pages/account/VerifyChangeEmail'
import AccountRegistration from './pages/account/AccountRegistration'
import ReportCompromised from './pages/account/ReportCompromised'
import RegistrationComplete from './pages/account/RegistrationComplete'
import SearchResults from './pages/SearchResults'
import ResendEmailVerification from './pages/account/ResendEmailVerification'
import VerifyEmail from './pages/account/VerifyEmail'
import VerifyEmailComplete from './pages/account/VerifyEmailComplete'
import PinRequestSuccess from './pages/pin/PinRequestSuccess'
import PinRequired from './pages/pin/PinRequired'
import Contact from './pages/Contact'
import PinRequest from './pages/pin/PinRequest'
import ChangePinSecurityQuestions from './pages/pin/ChangePinSecurityQuestions'

import VerifyPin from './pages/pin/VerifyPin'
import ResetPin from './pages/pin/ResetPin'
import PinSignature from './pages/pin/PinSignature'
import ManageUser from './pages/admin/ManageUser'
import AdminUpdateAccountDetails from './pages/admin/AdminUpdateAccountDetails'
import SearchUsers from './pages/admin/SearchUsers'
import ManagePortlets from './pages/admin/ManagePortlets'
import SearchIndex from './pages/admin/SearchIndex'
import Admin from './pages/admin/Admin'
import DestinationHealthCheck from './pages/admin/DestinationHealthCheck'
import ManageDestinations from './pages/admin/ManageDestinations'

export default [
    {
        path: '/go/:uri',
        name: 'Home',
        Component: () => (
            <>
                <HomePageInfo />
                <div id="portlet-container">
                    <MainPortletArea />
                </div>
            </>
        ),
        exact: false,
        protectedRoute: false,
        crumbable: false,
    },
    {
        path: '/',
        name: 'Root Redirect Home',
        Component: () => <Redirect to="/go/home" />,
        exact: true,
        protectedRoute: false,
        crumbable: false,
    },
    {
        path: '/go',
        name: 'Redirect Home',
        Component: () => <Redirect to="/go/home" />,
        exact: false,
        protectedRoute: false,
        crumbable: false,
    },
    {
        path: '/account/signin',
        name: 'Sign In',
        Component: () => <SignIn />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/incompleteProfile',
        name: 'Incomplete Profile',
        Component: () => <IncompleteProfile />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/signout',
        name: 'Sign Out',
        Component: () => <SignOut />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/resetPassword',
        name: 'Reset Password',
        Component: () => <ResetPassword />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/sitemap',
        name: 'Site Map',
        Component: () => <SiteMap />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/verifyResetPassword/:confirmationId',
        name: 'Verify Reset Password',
        Component: () => <VerifyResetPassword />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/details',
        name: 'Account Details',
        Component: () => <AccountDetails />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/account/changeSecurity',
        name: 'Change Security Question',
        Component: () => <ChangeSecurityQuestion />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/account/changePassword',
        name: 'Change Password',
        Component: () => <ChangePassword />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/account/updateDetails',
        name: 'Update Account Details',
        Component: () => <UpdateAccountDetails />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/account/changeEmail',
        name: 'Change E-mail',
        Component: () => <ChangeEmail />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/account/verifyChangeEmail',
        name: 'Verify E-mail Change',
        Component: () => <VerifyChangeEmail />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/account/register',
        name: 'Register',
        Component: () => <AccountRegistration />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/reportCompromised',
        name: 'Report Compromised Account',
        Component: () => <ReportCompromised />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/registrationComplete',
        name: 'Registration Complete',
        Component: () => <RegistrationComplete />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/search',
        name: 'Search',
        Component: () => <SearchResults />,
        exact: false,
        protectedRoute: false,
    },

    {
        path: '/account/resendVerificationEmail',
        name: 'Re-send E-mail Verification',
        Component: () => <ResendEmailVerification />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/verifyEmail/:confirmationId',
        name: 'Verify E-mail',
        Component: () => <VerifyEmail />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/account/verifyEmailComplete',
        name: 'E-mail Verification Complete',
        Component: () => <VerifyEmailComplete />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/pin/requestComplete',
        name: 'PIN Request Complete',
        Component: () => <PinRequestSuccess />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/pin/pinRequired',
        name: 'PIN Required',
        Component: () => <PinRequired />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/contact',
        name: 'Contact Us',
        Component: () => <Contact />,
        exact: true,
        protectedRoute: false,
    },

    {
        path: '/pin/request',
        name: 'PIN Request',
        Component: () => <PinRequest />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/pin/changeSecurity',
        name: 'Change PIN Security Questions',
        Component: () => <ChangePinSecurityQuestions />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/pin/reset',
        name: 'Reset PIN',
        Component: () => <ResetPin />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/pin/verify',
        name: 'Verify PIN',
        Component: () => <VerifyPin />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/pin/sign',
        name: 'PIN Signature',
        Component: () => <PinSignature />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/pin/sign!create',
        name: 'PIN Signature',
        Component: () => <PinSignature />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/admin/',
        name: 'Admin',
        Component: () => <Admin />,
        exact: true,
        protectedRoute: true,
    },

    {
        path: '/admin/manageUser/:userId',
        name: 'Manage User',
        Component: () => <ManageUser />,
        exact: true,
        protectedRoute: true,
    },
    {
        path: '/admin/updateAccountDetails/:userId',
        name: 'Update Account',
        Component: () => <AdminUpdateAccountDetails />,
        exact: true,
        protectedRoute: true,
    },
    {
        path: '/admin/userSearch',
        name: 'User Search',
        Component: () => <SearchUsers />,
        exact: false,
        protectedRoute: true,
    },
    {
        path: '/admin/searchIndex',
        name: 'Search Index',
        Component: () => <SearchIndex />,
        exact: true,
        protectedRoute: true,
    },
    // {
    //     path: '/admin/portlets/new',
    //     name: 'New Portlet',
    //     Component: () => <CreateNewPortlet />,
    //     exact: true,
    //     protectedRoute: true,
    // },
    {
        path: '/admin/portlets',
        name: 'Portlets',
        Component: () => <ManagePortlets />,
        exact: true,
        protectedRoute: true,
    },
    {
        path: '/admin/destinations',
        name: 'Destinations',
        Component: () => <ManageDestinations />,
        exact: true,
        protectedRoute: true,
    },
    {
        path: '/admin/destinationHealthCheck',
        name: 'Destination Health',
        Component: () => <DestinationHealthCheck />,
        exact: true,
        protectedRoute: true,
    },
]
