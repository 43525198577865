import React from 'react'
import { Link } from 'react-router-dom'

function PinRequired() {
    return (
        <div className="content-pane">
            <h1>PIN Required</h1>
            <p>
                You need to have a PIN before continuing. If you have submitted
                a request for a PIN, it may still be in processing. If you have
                not yet submitted a request,{' '}
                <Link to={`/pin/request`}>apply for a pin</Link>.
            </p>
        </div>
    )
}

export default PinRequired
