import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'


function UserSearchForm() {
    const history = useHistory()
    const { partial, query } = useParams()
    const [searchParams, setSearchParams] = useState({
        partial: partial || true,
        query: query || ``,
        index: 0,
    })

    const search = () => {
        const idx = searchParams.index + 1
        setSearchParams({ ...searchParams, index: idx })
        history.push(
            `/admin/userSearch/${searchParams.partial}/${encodeURIComponent(
                searchParams.query
            )}/${idx}`
        )
    }
    // const searchOnEnterKey = (e) => {
    //     if (e.key === `Enter`) {
    //         e.preventDefault()
    //         doSearch()
    //     }
    // }

    return (
        <>
            <form onSubmit={() => search()}>
                <div style={{ marginBottom: `1em` }}>
                    <span
                        style={{
                            fontWeight: `bold`,
                        }}
                    >
                        Note:
                    </span>
                    When using partial matching, this search may take 15 seconds
                    or more to return results.
                </div>

                <label htmlFor="query">
                    Query
                    <span className="required">*</span>:
                </label>

                <input
                    required
                    onChange={(e) =>
                        setSearchParams({
                            ...searchParams,
                            query: e.target.value,
                        })
                    }
                    size={150}
                    defaultValue={query}
                    value={searchParams.query}
                />
                <label htmlFor="partial">
                    <input
                        name="partial"
                        id="partial"
                        type="checkbox"
                        checked={searchParams.partial}
                        onChange={(e) =>
                            setSearchParams({
                                ...searchParams,
                                partial: e.currentTarget.checked,
                            })
                        }
                    />
                    Support Partial Matching?
                </label>

                <input type={`submit`} className={`userSearchButton`} value={`Search`} />
            </form>
        </>
    )
}

export default UserSearchForm
