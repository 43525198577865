import React from 'react'
import PinSet from "./PinSet"

const VerifyPin = () => (
    <PinSet
        title={`Verify PIN`}
        message={`Please confirm your new PIN by answering your security question below and re-entering your PIN. Remember to keep your PIN in a safe place.`}
    />
)

export default VerifyPin