import { createSlice } from '@reduxjs/toolkit'

const manageUsersSlice = createSlice({
    name: 'manageUsers',
    initialState: {},
    reducers: {
        
    },
    extraReducers: {},
})

export default manageUsersSlice.reducer
