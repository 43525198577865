import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchData, postData, putData } from '../http/httpUtil'

const storageKey = `account`
const initialState = JSON.stringify({ accountRegistrationResponse: null })

const saveState = () => {
    /* localStorage.setItem(storageKey, JSON.stringify(state)) */
}

const loadState = () =>
    JSON.parse(localStorage.getItem(storageKey) || initialState)

// creates a Redux wrapper around an async function, like for fetching data
// the first parameter is just a key for Redux, not an actual URI
export const fetchSecurityQuestions = createAsyncThunk(
    'account/fetchSecurityQuestions',
    async () => {
        return await fetchData('/api/security-questions/valid-questions/')
    }
)

export const fetchAccountSecurityQuestion = createAsyncThunk(
    'account/fetchAccountSecurityQuestion',
    async () => {
        return await fetchData(
            `/api/security-questions/selected-account-question`
        )
    }
)

export const fetchAccountDetails = createAsyncThunk(
    'account/fetchAccountDetails',
    async () => {
        return await fetchData(
            `/api/accounts/details/`
        )
    }
)

export const checkEmailAvailability = createAsyncThunk(
    'account/checkEmailAvailability',
    async (email) => {
        return await fetchData(`/api/accounts/is-email-available/${email}`)
    }
)

export const updateAccount = createAsyncThunk(
    'account/updateAccount',
    async (account) => {
        return await putData(`/api/accounts/details/`, account)
    }
)
export const createAccount = createAsyncThunk(
    'account/createAccount',
    async (account) => {
        return await postData(`/api/accounts/register-account`, account)
    }
)

const fetchAccountDetailsReducer = (state, action) => {
    const newState = { ...state, accountDetails: action.payload }
    saveState(newState)
    return newState
}
const fetchSecurityQuestionsResponseReducer = (state, action) => {
    const newState = { ...state, securityQuestions: action.payload }
    saveState(newState)
    return newState
}

const fetchAccountSecurityQuestionResponseReducer = (state, action) => {
    const newState = { ...state, accountSecurityQuestion: action.payload }
    saveState(newState)
    return newState
}

const createAccountReducer = (state, action) => {
    const p = action.payload

    if (p.status === 400 && p.errors) {
        const errors = {}
        p.errors.forEach(
            (error) =>
                //(errors[error.field] = `Invalid value: ${error.rejectedValue}`)
                (errors[error.field] = error.defaultMessage || `Invalid value: ${error.rejectedValue}`)
        )
        return { ...state, errors }
    }

    const newState = { ...state, ...action.payload }
    saveState(newState)
    return newState
}
const updateAccountReducer = (state, action) => {
    const p = action.payload

    if (p.status === 400 && p.errors) {
        const errors = {}
        p.errors.forEach(
            (error) =>
                (errors[error.field] = `Invalid value: ${error.rejectedValue}`)
        )
        return { ...state, errors }
    }

    const newState = { ...state, ...action.payload }
    saveState(newState)
    return newState
}

const accountSlice = createSlice({
    name: 'account',
    initialState: loadState(),
    reducers: {},
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchSecurityQuestions.fulfilled]: fetchSecurityQuestionsResponseReducer,
        [fetchSecurityQuestions.rejected]: fetchSecurityQuestionsResponseReducer,
        [fetchAccountSecurityQuestion.fulfilled]: fetchAccountSecurityQuestionResponseReducer,
        [fetchAccountSecurityQuestion.rejected]: fetchAccountSecurityQuestionResponseReducer,
        [createAccount.fulfilled]: createAccountReducer,
        [createAccount.rejected]: createAccountReducer,
        [fetchAccountDetails.fulfilled]: fetchAccountDetailsReducer,
        [fetchAccountDetails.rejected]: fetchAccountDetailsReducer,
        [updateAccount.fulfilled]: updateAccountReducer,
        [updateAccount.rejected]: updateAccountReducer,
    },
})

export default accountSlice.reducer
