import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { postData, useGet } from '../../features/http/httpUtil'
import { ShowError } from '../../components/ShowError'

function VerifyEmail() {
    const [params] = useState(useParams())
    const [confirmationId] = useState(params.confirmationId)

    const [securityAnswer, setSecurityAnswer] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')

    const [status, setStatus] = useState(`draft`)

    const confirmationUrl = confirmationId
        ? `/api/security-questions/by-confirmation-id/${confirmationId}`
        : null
    let {
        data: selectedSecurityQuestion,
        pending: securityQuestionLoading,
        error: securityQuestionError,
    } = useGet(confirmationUrl)

    if (confirmationId == null || confirmationId === '') {
        return <div>Confirmation id required.</div>
    }
    if (securityQuestionLoading) {
        return <p>Loading...</p>
    }
    if (securityQuestionError) {
        return <ShowError error={securityQuestionError} />
    }
    const handleForm = event => {
        event.preventDefault()
        if (newPassword !== confirmPassword) {
            setError("Passwords don't match.")
            return
        }

        setStatus(`pending`)
        postData('/api/accounts/verify-email', {
            confirmationId: confirmationId,
            questionAnswerPair: {
                confirmationId: confirmationId,
                securityAnswer: securityAnswer,
            },
            password: newPassword,
        }).then(result => {
            if (result.registrationSuccessful) {
                setStatus(`complete`)
            } else {
                setStatus(`error`)
                if (result.confirmationIdInvalid) {
                    setError('Confirmation id invalid.')
                } else if (result.statusNotEmail) {
                    setError('Account already verified.')
                } else if (result.securityAnswerInvalid) {
                    setError('Security answer is invalid.')
                }    
            }
        })
    }

    if (status === `complete`) {
        return <Redirect to="/account/details" />
    }
    return (
        <div className="content-pane">
            <h1>Verify E-mail</h1>
            <p>
                Congratulations! Your e-mail address has been verified. To
                complete your registration, please choose a password below.
                Passwords must be between 8 and 20 characters long and must
                contain at least one uppercase letter, one lowercase letter, and
                one number.
            </p>

            <form onSubmit={handleForm}>
                <input
                    type="hidden"
                    name="confirmationId"
                    value={confirmationId}
                />
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <span
                                    className="errorMessage"
                                    id="errorMessage"
                                >
                                    {error}
                                </span>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label
                                    htmlFor="securityAnswer"
                                    className="label"
                                >
                                    {selectedSecurityQuestion.question}
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    name="securityAnswer"
                                    size="25"
                                    maxLength="50"
                                    id="securityAnswer"
                                    autoFocus="autofocus"
                                    onChange={e =>
                                        setSecurityAnswer(e.target.value)
                                    }
                                    value={securityAnswer}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="newPassword" className="label">
                                    New Password
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="password"
                                    name="newPassword"
                                    size="20"
                                    maxLength="30"
                                    id="newPassword"
                                    onChange={e =>
                                        setNewPassword(e.target.value)
                                    }
                                    value={newPassword}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label
                                    htmlFor="confirmPassword"
                                    className="label"
                                >
                                    Confirm Password
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    size="20"
                                    maxLength="30"
                                    id="confirmPassword"
                                    onChange={e =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    value={confirmPassword}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <div align="right">
                                    <input
                                        type="submit"
                                        id=""
                                        value="Create Password"
                                        className="jq-button"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default VerifyEmail
