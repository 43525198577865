import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchPortlets } from '../features/portlet/portletSlice'
import '../App.css'

function Destination({ destination }) {
    return (
        <li>
            <a href={destination.url}>
                <span className="title">{destination.title}</span>
            </a>
        </li>
    )
}
function Portlet({ portlet }) {
    return (
        <li>
            <Link to={`/go/${portlet.uri}`}>{portlet.title}</Link>
            {portlet.portletType === `DESTINATION` && portlet.destinations && (
                <ul>
                    {portlet.destinations.map((d, key) => (
                        <Destination key={key} destination={d} />
                    ))}
                </ul>
            )}
            {portlet.portletType !== `DESTINATION` && portlet.children && (
                <ul>
                    {portlet.children.map((p, key) => (
                        <Portlet key={key} portlet={p} />
                    ))}
                </ul>
            )}
        </li>
    )
}
function SiteMap() {
    const portlets = useSelector((state) => state.portlet.portlets)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPortlets())
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (portlets && portlets.home) {
        return (
            <div className="content-pane">
                <ul>
                    <Portlet portlet={portlets.home} />
                    <li>
                        <Link to={`/contact/`}>Contact Us</Link>
                    </li>
                </ul>
            </div>
        )
    } else {
        return `Loading...`
    }
}

export default SiteMap
