import React, { useState } from 'react'
import { postData } from '../../features/http/httpUtil'
import { Link } from 'react-router-dom'

function ResetPassword() {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    //const dispatch = useDispatch()



    const handleForm = (event) => {
        event.preventDefault()
        postData('/api/accounts/reset-password', {
            email: email,
        }).then((result) => {
            if (result.message === 'success') {
                setMessage(
                    'Please check your e-mail. If an account matching the provided e-mail address exists, password reset instructions have been sent to it.'
                )
            } else {
                setError('An error occurred.')
            }
        })
    }

    return (
        <div className="content-pane">
            <h1>Reset Password</h1>

            <p>
                If you have forgotten your password, you can reset it below. If
                you no longer have access to the e-mail address on file, please
                {' '}<Link to="/contact/">
                    contact the DEP help desk
                </Link>
                .
            </p>

            <form onSubmit={handleForm}>
                <table className="formTable">
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                {error && (
                                    <span
                                        className="errorMessage"
                                        id="errorMessage"
                                    >
                                        {error}
                                    </span>
                                )}
                                {message && <span className="errorMessage" id="message">{message}</span>}
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="emailAddress" className="label">
                                    E-mail Address
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="email"
                                    className="emailAddress"
                                    size={40}
                                    maxLength={255}
                                    id="emailAddress"
                                    autofocus="autofocus"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div align="center">
                                    <input
                                        type="submit"
                                        id=""
                                        value="Continue"
                                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default ResetPassword
