import React, { useState } from 'react'
import { Redirect, useLocation, Link } from 'react-router-dom'
import { postData, useGet } from '../../features/http/httpUtil'
import { ShowError } from '../../components/ShowError'
import { useSelector } from 'react-redux'
import { decode } from 'jsonwebtoken'
import queryString from 'query-string'

const PinSet = ({ title, message }) => {
    const location = useLocation()

    const { internalRedirect } = queryString.parse(location.search)

    const [securityAnswer, setSecurityAnswer] = useState('')
    const [pin, setPin] = useState('')

    const [error, setError] = useState('')

    const [status, setStatus] = useState(`draft`)
    const authenticatedUser = useSelector((state) => state.auth.authResponse)

    const pinQuestionUrl = `/api/security-questions/random-pin-question`
    const signedPinTokenUrl = `/api/pin/generate-pin`

    const {
        data: selectedSecurityQuestion,
        pending: securityQuestionLoading,
        error: securityQuestionError,
    } = useGet(pinQuestionUrl)

    const {
        data: generatedToken,
        pending: signedPinTokenLoading,
        error: signedPinTokenError,
    } = useGet(signedPinTokenUrl)

    const generatedPin =
        generatedToken.signedPinToken &&
        decode(generatedToken.signedPinToken).entity

    if (securityQuestionLoading || signedPinTokenLoading) {
        return <p>Loading...</p>
    }
    if (
        authenticatedUser &&
        authenticatedUser.userInfoObject &&
        (!authenticatedUser.userInfoObject.pinContext ||
            authenticatedUser.userInfoObject.pinContext.pinApproved !== true)
    ) {
        return (
            <div className="content-pane">
                Your account ({authenticatedUser.uid}) has not been approved for
                a PIN. You may <Link to="/pin/request">request a pin</Link>.
            </div>
        )
    }
    if (securityQuestionError || signedPinTokenError) {
        return (
            <ShowError error={securityQuestionError || signedPinTokenError} />
        )
    }

    const handleForm = (event) => {
        event.preventDefault()

        if (parseInt(pin) !== parseInt(generatedPin)) {
            setStatus(`error`)
            setError('The PIN you entered does not match the provided PIN.')
            return
        } else {
            setError('')
        }
        setStatus(`pending`)
        postData('/api/pin/set-pin', {
            questionAnswerPair: {
                securityQuestionId: selectedSecurityQuestion.id,
                securityAnswer: securityAnswer,
            },
            pin: pin,
            signedPinToken: generatedToken.signedPinToken,
        }).then((result) => {
            if (result.pinVerified) {
                setStatus(`complete`)
            } else {
                setStatus(`error`)
                setError('Security answer incorrect.')
            }
        })
    }

    if (status === `complete`) {
        return <Redirect to={internalRedirect || '/account/details'} />
    }
    return (
        <div className="content-pane">
            <h1>{title}</h1>

            <form onSubmit={handleForm} autoComplete="off">
                <p>{message}</p>
                {error && (
                    <span className="errorMessage" id="errorMessage">
                        {error}
                    </span>
                )}
                <table className="formTable">
                    <tbody>
                        <tr>
                            <td className="tdLabel">
                                <label
                                    htmlFor="securityAnswer"
                                    className="label"
                                >
                                    {selectedSecurityQuestion.question}
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    name="securityAnswer"
                                    size="25"
                                    maxLength="50"
                                    id="securityAnswer"
                                    autoFocus="autofocus"
                                    required={true}
                                    onChange={(e) =>
                                        setSecurityAnswer(e.target.value)
                                    }
                                    value={securityAnswer}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                Your issued PIN is{' '}
                                <strong>{generatedPin}</strong>.
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="pin" className="label">
                                    Confirm PIN
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    name="pin"
                                    size="6"
                                    maxLength="4"
                                    required={true}
                                    id="pin"
                                    min={1000}
                                    max={9999}
                                    onChange={(e) => setPin(e.target.value)}
                                    value={pin}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div align="right">
                                    <input
                                        type="submit"
                                        id="verifyPinSubmit"
                                        value={title}
                                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default PinSet
