import React from 'react'

function VerifyEmailComplete() {
    return (
        <div id={"registrationComplete"} className="content-pane">
            <h1>E-mail Verification Complete</h1>
            <p>Thanks for verifying your email address. You can now <a href="/account/signin">sign in</a>.</p>
            <h2>Apply for a PIN?</h2>
            <p>A PIN is a secure way to electronically sign submissions to DEP. At this time, most submissions
                do <b>NOT</b> require PIN signature.
                When you begin a submission that requires a PIN, you will be notified that a PIN is required. If you
                require a PIN,
                <a href="/pin/request">you may apply here</a>.</p>

            <p><strong>Note:</strong> You can always apply for a PIN later by
                visiting your account details page. Once you are signed in, you can
                find your account details page by clicking your name on the sign-in
                bar above.</p>
        </div>
    )
}

export default VerifyEmailComplete