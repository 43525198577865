import { useState, useEffect } from 'react';

export const getAccessToken = () =>
    JSON.parse(localStorage.getItem(`e29273eca8e441c085fde5e2f964d743-portal-auth`) || `{}`).portalAuthToken

export function getBaseHeaders() {
    const accessToken = getAccessToken()
    const headers = {}
    if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`
    }
    return headers
}


export const defaultApiUrl = process.env.REACT_APP_API_URL || `/DepPortal`


export async function postAuth(url, data = {}, additionalHeaders = {}) {
    // Default options are marked with *
    const options = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            ...getBaseHeaders(),
            'Content-Type': 'application/json',
            ...additionalHeaders,
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    }
    const response = await fetch(url, options)
    return await response.json() // parses JSON response into native JavaScript objects
}


export async function postData(url = '', data = {}) {
    // Default options are marked with *
    const options = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            ...getBaseHeaders(),
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    }
    const response = await fetch(defaultApiUrl + url, options)
    return await response.json() // parses JSON response into native JavaScript objects
}

export async function putData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(defaultApiUrl + url, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            ...getBaseHeaders(),
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return await response.json() // parses JSON response into native JavaScript objects
}

export async function deleteData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(defaultApiUrl + url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            ...getBaseHeaders(),
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return await response.json() // parses JSON response into native JavaScript objects
}

export async function fetchData(url = '', extraOptions = {}) {
    // Default options are marked with *
    const response = await fetch(defaultApiUrl + url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        headers: getBaseHeaders(),
        referrerPolicy: 'no-referrer', // no-referrer, *client
        ...extraOptions
    })
    return await response.json() // parses JSON response into native JavaScript objects
}


export function useGet(url, extraDependencies = []) {
    const [state, setState] = useState({data: false, pending: true, error: false});
    useEffect(() => {
        const abortController = new AbortController();
    
        const doGet = async () => {
            setState({...state, pending: true, doGet: null})
    
          try {
            const result = await fetchData(url, { signal: abortController.signal });
            setState({...state, pending: false, data: result})
          } catch (e) {
            if (!abortController.signal.aborted) {
                setState({...state, pending: false, error: e})
              }
          }
        };
    
        doGet();
    
        return () => {
          abortController.abort();
        };
      }, [url, ...extraDependencies]) // eslint-disable-line react-hooks/exhaustive-deps
  
    return state;
  }
  

  