import React, { useEffect } from 'react'
import { Link, useParams, Redirect } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'
import { fetchPortlets } from '../../features/portlet/portletSlice'

const url = (uri) => `${uri}`

export const ChildPortlet = (props) => {
    const contextRoot = window.__DEP_PORTAL_DATA__
        ? window.__DEP_PORTAL_DATA__.contextRoot || '/DepPortal'
        : '/DepPortal'
    const { content, iconFileName, title, uri, displayType } = props.portlet
    return (
        <div
            id={`portlet_${uri}`}
            className={`portlet ${
                displayType === 'ACTION' ? 'portlet-action' : ''
            }`}
        >
            <Link to={`/go/${uri}`}>
                {iconFileName && displayType === 'FULL' && (
                    <img
                        src={url(`${contextRoot}/images/icons/${iconFileName}`)}
                        width="32"
                        height="32"
                        title={title}
                        alt={title}
                    />
                )}{' '}
                <span className="title">{title}</span>
                {displayType === 'FULL' && (
                    <span className="description">{content}</span>
                )}
            </Link>
        </div>
    )
}

export const Destination = (props) => {
    const { content, id, title, url } = props.destination
    return (
        <div className="destination">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={url} data-tip data-for={`tooltip-${id}`} target="_blank">
                {' '}
                <span className="title">{title}</span>
            </a>
            <ReactTooltip id={`tooltip-${id}`}>{content}</ReactTooltip>
        </div>
    )
}

export const HomePageInfo = () => {
    return (
        <div id="portal-intro" className="content-pane">
            <p
                style={{
                    textAlign: 'center',
                    marginBottom: '0',
                    marginTop: '0',
                }}
            >
                <span
                    style={{
                        fontSize: '28px',
                        fontWeight: 'bold',
                        lineHeight: '1.3em',
                    }}
                >
                    Welcome
                </span>
                <br />
                <span style={{ lineHeight: '2em' }}>to the</span>
            </p>
            Florida Department of Environmental Protection's Business Portal. If
            you don't find what you're looking for, please come back as we're
            continually adding to the site.
            {/* <p style={{ fontWeight: 'bold', color: 'red' }}>
                If you are trying to finish a previously started submission,
                please click "Continue" and then "Continue Working on an
                Incomplete Submission."
            </p> */}
            <div className="spacer-line"></div>
        </div>
    )
}
export const MainPortletArea = () => {
    const portlets = useSelector((state) => state.portlet.portlets)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPortlets())
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (portlets && portlets.home) {
        return <MainPortlet />
    }
    return `Loading...`
}

export const MainPortlet = () => {
    const { uri } = useParams()
    const portlet = useSelector((state) => state.portlet.portlets[uri])
    const { tagline, title, children, destinations } = portlet || {}
    useEffect(() => {
        document.title = `${title} | DEP Business Portal`
        return () => {}
    }, [title])
    if (!title) {
        return <Redirect to={`/go/home`} />
    }

    return (
        <>
            <div id="portlet-tagline">{tagline}:</div>
            {children.map((child) => (
                <ChildPortlet portlet={child} key={child.uri} />
            ))}
            {destinations.map((destination) => (
                <Destination destination={destination} key={destination.id} />
            ))}
        </>
    )
}
