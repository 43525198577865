/* thanks, IE. */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'raf/polyfill'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
//import 'core-js/shim'
import 'core-js/es/symbol'
import 'core-js/es/object'
import 'core-js/es/function'
import 'core-js/es/parse-int'
import 'core-js/es/parse-float'
import 'core-js/es/number'
import 'core-js/es/math'
import 'core-js/es/string'
import 'core-js/es/date'
import 'core-js/es/array'
import 'core-js/es/regexp'
import 'core-js/es/map'
import 'core-js/es/set'
import 'core-js/es/reflect'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'

const render = () => {
    const App = require('./App').default

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
