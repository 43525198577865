import React, { useState, useEffect } from 'react'
import { putData, useGet } from '../../features/http/httpUtil'
import { InputRow, InputTel, validate, hasError } from '../../components/forms'
import { useHistory, useParams } from 'react-router-dom'
import { ShowError } from '../../components/ShowError'
import AdminNav from './AdminNav'

const accountDetailsMetadata = {
    fields: {
        firstName: {
            title: 'First Name',
            required: true,
            size: 20,
            maxLength: 50,
        },
        middleInit: {
            title: 'Middle Name',
            required: false,
            size: 20,
            maxLength: 50,
        },
        lastName: {
            title: 'Last Name',
            required: true,
            size: 20,
            maxLength: 50,
        },
        email: {
            title: 'E-mail Address',
            required: true,
            size: 50,
            maxLength: 80,
        },
        addressLine1: {
            title: 'Address (Line 1)',
            required: true,
            size: 40,
            maxLength: 50,
        },
        addressLine2: {
            title: 'Address (Line 2)',
            required: false,
            size: 40,
            maxLength: 50,
        },
        city: {
            title: 'City',
            required: true,
            size: 30,
            maxLength: 50,
        },
        state: {
            title: 'State',
            required: true,
            size: 2,
            maxLength: 2,
        },
        postalCode: {
            title: 'Zip Code',
            required: true,
            size: 10,
            maxLength: 5,
        },
        phoneNumber: {
            title: 'Phone Number',
            required: true,
            size: 30,
            maxLength: 14,
        },
    },
}

const AdminUpdateAccountDetails = () => {
    const history = useHistory()

    const [state, setState] = useState({
        data: {},
        errors: {},
        meta: accountDetailsMetadata,
    })

    const { data, errors, meta } = state
    const formProps = { state, setState }

    const { userId } = useParams()

    const { data: userDetails, userDetailsPending, userDetailsError } = useGet(
        `/api/accounts/admin-details/${userId}`
    )

    useEffect(() => {
        setState({
            data: userDetails,
            errors: {},
            meta: accountDetailsMetadata,
        })
        return () => {}
    }, [userDetailsPending]) // eslint-disable-line react-hooks/exhaustive-deps

    if (userDetailsPending) {
        return `Loading...`
    }

    if (userDetailsError) {
        return <ShowError error={userDetailsError} />
    }

    const updateAccountDetails = (e) => {
        e.preventDefault()
        const formErrors = validate(data, meta)
        if (hasError(formErrors)) {
            setState({ ...state, errors: formErrors })
        } else {
            putData(`/api/accounts/details`, {
                uid: state.uid,
                firstName: state.firstName,
                middleName: state.middleInit,
                lastName: state.lastName,
                email: state.email,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                state: state.state,
                postalCode: state.postalCode,
                telephoneNumber: state.phoneNumber,
            }).then((result) => {
                if (result.status && result.status !== 200) {
                    let serverValidationErrors = {}
                    result.errors.forEach(function (r) {
                        serverValidationErrors[r.field] = r.defaultMessage
                    })

                    setState({
                        ...state,
                        errors: {
                            ...state.errors,
                            ...serverValidationErrors,
                            saveError: result,
                        },
                    })
                } else {
                    history.goBack()
                }
            })
        }
    }

    return (
        <div id="updateAccountInformation" className={`admin-content-pane`}>
            <AdminNav />
            <h1>Update Account Information</h1>

            <form onSubmit={updateAccountDetails}>
                <table>
                    <tbody>
                        {errors && errors.saveError && (
                            <tr>
                                <td colSpan="2">
                                    <span
                                        className="errorMessage"
                                        id="errorMessage"
                                    >
                                        There was a problem with your request
                                    </span>
                                    <br />
                                </td>
                            </tr>
                        )}

                        <InputRow name="firstName" {...formProps} />

                        <InputRow name="middleInit" {...formProps} />

                        <InputRow name="lastName" {...formProps} />

                        <InputRow name="email" {...formProps} />

                        <InputRow name="addressLine1" {...formProps} />

                        <InputRow name="addressLine2" {...formProps} />

                        <InputRow name="city" {...formProps} />

                        <InputRow name="state" {...formProps} />

                        <InputRow name="postalCode" {...formProps} />

                        <InputRow
                            name="telephoneNumber"
                            {...formProps}
                            InputComponent={InputTel}
                        />
                        <tr>
                            <td colSpan="2" align="right">
                                <input
                                    type="submit"
                                    value="Update Account Information"
                                    className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default AdminUpdateAccountDetails
