import React, { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { signOut } from '../../features/auth/authSlice'
import queryString from 'query-string'

function SignOut() {
    const location = useLocation()
    const { redirect } = queryString.parse(location.search)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(signOut({ redirect }))
    }, [redirect]) // eslint-disable-line react-hooks/exhaustive-deps

    if (redirect) {
        return null
    } else {
        return <Redirect to="/" />
    }
}

export default SignOut
