import React from 'react'
import {useSelector} from 'react-redux'

function PinRequestSuccess() {
    const userInfoObject = useSelector(
        state => state.auth.authResponse.userInfoObject
    );

    return (
        <div className="content-pane">
            <h1>PIN Request</h1>
            <p>We have sent an email to <strong>{userInfoObject.email}</strong> with
                a form which must be notarized and mailed to us. Full instructions
                are included in the email.</p>

            <h1>PIN Request</h1>

            <p>
                In order to electronically sign documents submitted to DEP, you
                must obtain a PIN. To apply for a PIN, please follow the
                instructions below.
            </p>
        </div>
    );
}

export default PinRequestSuccess