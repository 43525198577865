import React, { useState, useEffect } from 'react'
import { putData, useGet } from '../../features/http/httpUtil'
import { useSelector } from 'react-redux'
import { InputRow, InputTel, validate, hasError } from '../../components/forms'
import { useHistory } from 'react-router-dom'

const accountDetailsMetadata = {
    fields: {
        firstName: {
            title: 'First Name',
            required: true,
            size: 20,
            maxLength: 50,
        },
        middleName: {
            title: 'Middle Name',
            required: false,
            size: 20,
            maxLength: 50,
        },
        lastName: {
            title: 'Last Name',
            required: true,
            size: 20,
            maxLength: 50,
        },
        addressLine1: {
            title: 'Address (Line 1)',
            required: true,
            size: 40,
            maxLength: 50,
        },
        addressLine2: {
            title: 'Address (Line 2)',
            required: false,
            size: 40,
            maxLength: 50,
        },
        city: {
            title: 'City',
            required: true,
            size: 30,
            maxLength: 50,
        },
        state: {
            title: 'State',
            required: true,
            size: 2,
            maxLength: 2,
        },
        zipCode: {
            title: 'Zip Code',
            required: true,
            size: 10,
            maxLength: 5,
        },
        phone: {
            title: 'Phone Number',
            required: true,
            size: 30,
            maxLength: 14,
        },
    },
}

const UpdateAccountDetails = () => {
    const authenticatedUser = useSelector((state) => state.auth.authResponse)

    const history = useHistory()

    const {
        data: accountDetails,
        pending: accountDetailsLoading
    } = useGet(`/api/accounts/details`)

    const [state, setState] = useState({
        data: {},
        errors: {},
        meta: accountDetailsMetadata,
    })

    const { data, errors, meta } = state
    const formProps = {state, setState}


    useEffect(() => {
        setState({data: accountDetails, errors: {}, meta: accountDetailsMetadata})
        return () => {}
    }, [accountDetailsLoading]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const updateAccountDetails = (e) => {
        e.preventDefault()
        const formErrors = validate(data, meta)
        if (hasError(formErrors)) {
            setState({ ...state, errors: formErrors })
        } else {
            putData(`/api/accounts/details`, {
                ...data,
                uid: authenticatedUser.uid,
            }).then((result) => {
                if (result.status && result.status !== 200) {
                    let serverValidationErrors = {}
                    result.errors.forEach(function(r) {
                        serverValidationErrors[r.field] = r.defaultMessage
                    })
                    
                    setState({
                        ...state,
                        errors: { ...state.errors, ...serverValidationErrors, saveError: result },
                    })
                } else {
                    //dispatch(fetchPortlets())

                    history.push(`/account/details`)
                }
            })
        }
    }

    if (accountDetailsLoading || !accountDetails) {
        return `Loading...`
    }
    
    return (
        <div id="updateAccountInformation" className="content-pane">
            <h1>Update Account Information</h1>

            <form onSubmit={updateAccountDetails}>
                <table>
                    <tbody>
                        {errors && errors.saveError && (
                            <tr>
                                <td colSpan="2">
                                    <span
                                        className="errorMessage"
                                        id="errorMessage"
                                    >
                                        There was a problem with your request
                                    </span>
                                    <br />
                                </td>
                            </tr>
                        )}

                        <InputRow name="firstName" {...formProps} />

                        <InputRow name="middleName" {...formProps} />

                        <InputRow name="lastName" {...formProps} />

                        <InputRow name="addressLine1" {...formProps} />

                        <InputRow name="addressLine2" {...formProps} />

                        <InputRow name="city" {...formProps} />

                        <InputRow name="state" {...formProps} />

                        <InputRow name="zipCode" {...formProps} />

                        <InputRow
                            name="phone"
                            {...formProps}
                            InputComponent={InputTel}
                        />
                        <tr>
                            <td colSpan="2" align="right">
                                <input
                                    type="submit"
                                    value="Update Account Information"
                                    className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default UpdateAccountDetails
