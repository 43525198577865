import React from 'react'
import { Link } from 'react-router-dom'

const AdminNav = () => {
    return (
        <div className="ribbon-menu" role="menu">
            <Link to="/admin/">
                <div className="menu-item">Admin Home</div>
            </Link>

            <Link to="/admin/userSearch">
                <div className="menu-item">User Search</div>
            </Link>

            <Link to="/admin/portlets">
                <div className="menu-item">Portlets</div>
            </Link>

            <Link to="/admin/destinations">
                <div className="menu-item">Destinations</div>
            </Link>

            <Link to="/admin/destinationHealthCheck">
                <div className="menu-item">Destination Health</div>
            </Link>

            <Link to="/admin/searchIndex">
                <div className="menu-item">Search Index</div>
            </Link>
        </div>
    )
}

export default AdminNav
