import React, { useEffect, useState } from 'react'
import { ShowError } from '../../components/ShowError'
import { useGet, putData } from '../../features/http/httpUtil'
import { useParams } from 'react-router-dom'
import AdminNav from './AdminNav'
import { useSelector } from 'react-redux'

const formatMillisToDateTime = (m) => {
    if (!m) {
        return ''
    } else {
        let d = new Date(m)
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
    }
}

function hasRole(authenticatedUser, roleName) {
    try {
        return (
            authenticatedUser.userInfoObject.securityContext.securityRoles.filter(
                (r) => r.name === roleName
            ).length > 0
        )
    } catch (error) {
        return false
    }
}

function ManageUser() {
    const authenticatedUser = useSelector((state) => state.auth.authResponse)

    const { userId } = useParams()

    const {
        data: user,
        pending,
        error,
    } = useGet(`/api/info/user-details?uid=${userId}`)

    const [message, setMessage] = useState()
    const [updateErrors, setUpdateErrors] = useState()
    const [newPassword, setNewPassword] = useState()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [addressLine1, setAddressLine1] = useState('')
    const [addressLine2, setAddressLine2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [email, setEmail] = useState('')
    const [fullName, setFullName] = useState('')
    const [externalUser, setExternalUser] = useState('')
    const [pinContext, setPinContext] = useState('')
    const [ldapDN, setLdapDN] = useState('')

    useEffect(() => {
        if (user[0]) {
            setFirstName(user[0].firstName)
            setLastName(user[0].lastName)
            setPhoneNumber(user[0].phoneNumber)
            setAddressLine1(user[0].addressLine1)
            setAddressLine2(user[0].addressLine2)
            setCity(user[0].city)
            setState(user[0].state)
            setZipCode(user[0].zipCode)
            setEmail(user[0].email)
            setFullName(user[0].fullName)
            setExternalUser(user[0].externalUser)
            setPinContext(user[0].pinContext)
            setLdapDN(user[0].ldapDN)
        }
    }, [user, user[0]])

    const isAppAdmin = hasRole(authenticatedUser, `App Admin`)

    const editable = isAppAdmin === true && externalUser === true

    const { data: account, pending: accountPending } = useGet(
        `/api/accounts/${userId}`
    )

    if (pending || accountPending) {
        return `Loading...`
    }

    if (error) {
        return <ShowError error={error} />
    }
    if (!user && user.length < 1) {
        return `Not found`
    }

    /* eslint-enable */
    const dummyPinContext = {
        pinApproved: false,
        pinVerified: false,
        pinLocked: false,
    }

    const lastLogin = account && formatMillisToDateTime(account.lastLogin)

    const updateAccount = () => {
        const payload = {
            uid: userId,
            firstName: firstName,
            lastName: lastName,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            state: state,
            zipCode: zipCode,
            phone: phoneNumber,
            email: email,
        }
        putData(`/api/accounts/update/${userId}`, payload).then((result) => {
            if (result.status && result.status !== 200) {
                if (result.errors && result.errors.length > 0) {
                    setUpdateErrors(
                        `Error updating account:\n${result.errors
                            .map((e) => e.defaultMessage)
                            .join('\n')}`
                    )
                } else {
                    setUpdateErrors(
                        `Error updating account:\n${JSON.stringify(result)}`
                    )
                }
            } else {
                setMessage('Account updated')
                window.location.reload()
            }
        })
    }

    const lockAccount = () =>
        putData(`/api/accounts/lock/${userId}`).then((result) => {
            if (result.status && result.status !== 200) {
                setMessage(`Error locking account: ${result.status}`)
            } else {
                setMessage('Account locked')
                window.location.reload()
            }
        })
    const unlockAccount = () =>
        putData(`/api/accounts/unlock/${userId}`).then((result) => {
            if (result.status && result.status !== 200) {
                setMessage(`Error unlocking account: ${result.status}`)
            } else {
                setMessage('Account unlocked')
                window.location.reload()
            }
        })

    const lockPin = () =>
        putData(`/api/pin/lock/${userId}`).then((result) => {
            if (result.status && result.status !== 200) {
                setMessage(`Error locking PIN: ${result.status}`)
            } else {
                setMessage('PIN locked')
                window.location.reload()
            }
        })
    const unlockPin = () =>
        putData(`/api/pin/unlock/${userId}`).then((result) => {
            if (result.status && result.status !== 200) {
                setMessage(`Error unlocking PIN: ${result.status}`)
            } else {
                setMessage('PIN unlocked')
                window.location.reload()
            }
        })

    const resetPassword = () =>
        putData(`/api/accounts/reset-password/${userId}`).then((result) => {
            if (result.status && result.status !== 200) {
                setNewPassword(`Error: ${result.status}`)
            } else {
                setNewPassword(result.newPassword)
            }
        })

    const YesNo = ({ b }) => {
        return b === true ? `Yes` : `No`
    }
    const PinInfo = ({ pc }) => {
        return (
            <table>
                <tbody>
                    <tr>
                        <td>PIN approved?</td>
                        <td>
                            <YesNo b={pc.approved} />
                        </td>
                    </tr>
                    <tr>
                        <td>PIN verified?</td>
                        <td>
                            <YesNo b={pc.verified} />
                        </td>
                    </tr>
                    <tr>
                        <td>PIN locked?</td>
                        <td>
                            <YesNo b={pc.locked} />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
    return (
        <div className={`admin-content-pane`}>
            <AdminNav />
            <pre>{message}</pre>
            <h1>Manage User</h1>
            <h2>User Information</h2>
            <table>
                <tbody>
                    <tr>
                        <td>User ID:</td>
                        <td>{userId}</td>
                    </tr>
                    {isAppAdmin ? (
                        <tr>
                            <td>Distinguished Name:</td>
                            <td>{ldapDN}</td>
                        </tr>
                    ) : (
                        <></>
                    )}
                    <tr>
                        <td>Name:</td>
                        <td>
                            {editable ? (
                                <>
                                    <label htmlFor="firstName">
                                        First:{` `}
                                    </label>
                                    <input
                                        id="firstName"
                                        type="text"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        placeholder="First Name"
                                        size={20}
                                        maxLength={50}
                                        required
                                    />
                                    <label htmlFor="lastName">{` Last: `}</label>
                                    <input
                                        id="lastName"
                                        type="text"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        placeholder="Last Name"
                                        size={20}
                                        maxLength={50}
                                        required
                                    />
                                </>
                            ) : (
                                <span>{fullName}</span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>Account Type:</td>
                        <td>{externalUser ? `External` : `Internal`} User</td>
                    </tr>
                    <tr>
                        <td>E-mail Address:</td>
                        <td>
                            {editable ? (
                                <>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        placeholder="E-mail Address"
                                        size={40}
                                        maxLength={255}
                                        required
                                    />
                                </>
                            ) : (
                                <span>{email}</span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>Phone Number:</td>
                        <td>
                            {editable ? (
                                <>
                                    <input
                                        type="tel"
                                        value={phoneNumber}
                                        onChange={(e) =>
                                            setPhoneNumber(e.target.value)
                                        }
                                        placeholder="Phone Number"
                                        size={40}
                                        maxLength={14}
                                        required
                                    />
                                </>
                            ) : (
                                <span>{phoneNumber}</span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        {editable ? (
                            <td>
                                <label htmlFor="addressLine1">
                                    Address (Line 1):{` `}
                                </label>
                                <input
                                    id="addressLine1"
                                    type="text"
                                    size={40}
                                    maxLength={50}
                                    placeholder="Address (Line 1)"
                                    required
                                    onChange={(e) =>
                                        setAddressLine1(e.target.value)
                                    }
                                    value={addressLine1}
                                />
                                <br />

                                <label htmlFor="addressLine2">
                                    Address (Line 2):{` `}
                                </label>
                                <input
                                    id="addressLine2"
                                    type="text"
                                    size={40}
                                    maxLength={50}
                                    placeholder="Address (Line 2)"
                                    onChange={(e) =>
                                        setAddressLine2(e.target.value)
                                    }
                                    value={addressLine2}
                                />
                                <br />

                                <label htmlFor="city">City:{` `}</label>
                                <input
                                    id="city"
                                    type="text"
                                    size={30}
                                    maxLength={50}
                                    placeholder="City"
                                    required
                                    onChange={(e) => setCity(e.target.value)}
                                    value={city}
                                />
                                <br />

                                <label htmlFor="state">State:{` `}</label>
                                <input
                                    id="state"
                                    type="text"
                                    size={2}
                                    maxLength={2}
                                    placeholder="ST"
                                    required
                                    onChange={(e) => setState(e.target.value)}
                                    value={state}
                                />
                                <br />

                                <label htmlFor="zipCode">Zip Code:{` `}</label>
                                <input
                                    id="zipCode"
                                    type="text"
                                    size={10}
                                    maxLength={5}
                                    placeholder="Zip"
                                    required
                                    onChange={(e) => setZipCode(e.target.value)}
                                    value={zipCode}
                                />
                            </td>
                        ) : (
                            <td>
                                {addressLine1}
                                {addressLine2 && (
                                    <>
                                        <br />
                                        {addressLine2}
                                    </>
                                )}
                                <br />
                                {city}, {state} {zipCode}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td>Last Login:</td>
                        <td>{lastLogin}</td>
                    </tr>
                    {editable ? (
                        <>
                            {updateErrors ? (
                                <tr>
                                    <td colSpan={2}>
                                        <pre>{updateErrors}</pre>
                                    </td>
                                </tr>
                            ) : (
                                <></>
                            )}
                            <tr>
                                <td colSpan={2}>
                                    <input
                                        type="button"
                                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                        onClick={updateAccount}
                                        value="Update Account"
                                    />
                                </td>
                            </tr>
                        </>
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
            <h2>Account Status</h2>
            <p>Account status is: {account.status || `OK`}</p>
            {externalUser ? (
                account.status !== 'LOCKED' ? (
                    <input
                        type="button"
                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                        onClick={lockAccount}
                        value="Lock Account"
                    />
                ) : (
                    <input
                        type="button"
                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                        onClick={unlockAccount}
                        value="Unlock Account"
                    />
                )
            ) : (
                <span>Internal accounts are managed by the Service Desk</span>
            )}
            <h2>Reset Password</h2>
            {newPassword ? <p>New password: {newPassword}</p> : <></>}
            {externalUser ? (
                newPassword ? (
                    <></>
                ) : (
                    <input
                        type="button"
                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                        onClick={resetPassword}
                        value="Reset Password"
                    />
                )
            ) : (
                <span>
                    Internal account passwords must be reset by the Service Desk
                </span>
            )}
            <h2>PIN</h2>

            <PinInfo pc={pinContext || dummyPinContext} />
            {pinContext ? (
                pinContext.locked ? (
                    <input
                        type="button"
                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                        onClick={unlockPin}
                        value="Unlock PIN"
                    />
                ) : (
                    <input
                        type="button"
                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                        onClick={lockPin}
                        value="Lock PIN"
                    />
                )
            ) : (
                <p>No PIN information available</p>
            )}
        </div>
    )
}

export default ManageUser
