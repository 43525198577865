import React, { useState } from 'react'
import { useGet } from '../../features/http/httpUtil'
import { ShowError } from '../../components/ShowError'
import { Link, useParams, Route, useHistory } from 'react-router-dom'
import AdminNav from './AdminNav'
import DataTable, { createTheme } from 'react-data-table-component'
import UserSearchForm from './components/UserSearchForm'
/*
TODO for Donna:
- make user search default page
- make email query box 150 chars wide
- bold header text in search results, blue background :|
*/

// const tableStyle = {
//     headCells: {
//         style: {
//             backgroundColor: `#1d3582`,
//             color: `white`,
//         },
//     },
//     cells: {
//         style: {
//             textOverflow: `unset`,
//         },
//     },
// }

const formatMillisToDateTime = (m) => {
    if (!m) {
        return ``
    } else {
        let d = new Date(m)
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
    }
}

const columns = [
    {
        name: `ID`,
        selector: `uid`,
        sortFunction: (a, b) =>
            a.uid.toLowerCase().localeCompare(b.uid.toLowerCase()),
        grow: 2,
        sortable: true,
        cell: (row) => (
            <div>
                <Link to={`/admin/manageUser/${row.uid}`}>{row.uid}</Link>
            </div>
        ),
    },
    {
        name: `Name`,
        selector: `name`,
        sortFunction: (a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        sortable: true,
        wrap: true,
        grow: 2,
    },
    {
        name: `E-mail Address`,
        selector: `email`,
        sortFunction: (a, b) =>
            a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
        sortable: true,
        allowOverflow: true,
        wrap: true,
        grow: 3,
    },
    {
        name: `Last Login`,
        selector: `lastLogin`,
        grow: 1,
        sortable: true,
        cell: (row) => <div>{formatMillisToDateTime(row.lastLogin)}</div>,
    },
    {
        name: `Status`,
        selector: `status`,
        grow: 0,
        sortable: true,
        compact: true,
    },
]

function SearchResults() {
    const { partial, query, index } = useParams()
    const {
        data,
        pending,
        error,
    } = useGet(
        `/api/accounts?query=${encodeURIComponent(query)}&partial=${partial}`,
        [partial, query, index]
    )

    if (pending) return `Loading...`

    if (error) return <ShowError error={error} />

    return (
        <DataTable
            keyField={`uid`}
            title={`Search Results`}
            columns={columns}
            data={data}
            pagination={true}
            defaultSortAsc={true}
            defaultSortField={`uid`}
            striped={true}
            paginationPerPage={15}
            paginationRowsPerPageOptions={[10, 15, 20, 30, 50, 100]}
            // customStyles={tableStyle}
        />
    )
}

function SearchUsers() {
    const { partial, query } = useParams()

    return (
        <div className={`admin-content-pane`}>
            <AdminNav />
            <h1>User Search</h1>
            <UserSearchForm />
            <Route path={`/admin/userSearch/:partial/:query/:index`}>
                <SearchResults />
            </Route>
        </div>
    )
}

export default SearchUsers
