import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { postAuth } from '../http/httpUtil'
import { decode } from 'jsonwebtoken'
const storageKey = `e29273eca8e441c085fde5e2f964d743-portal-auth`
const saveAuth = (auth) =>
window.__DEP_PORTAL_DATA__.crossDomainStorageListeners.forEach(listener => listener('set', storageKey, JSON.stringify(auth)))
//    localStorage.setItem(storageKey, JSON.stringify(auth))
const loadAuth = () => {
    let ret = JSON.parse(localStorage.getItem(storageKey) || `{}`)
    if (!ret.status || ret.status !== `success` || !ret.portalAuthToken) {
        localStorage.removeItem(storageKey)
        return {}
    }
    let authExpiration = decode(ret.portalAuthToken).exp
    if (Date.now() >= authExpiration * 1000) {
        localStorage.removeItem(storageKey)
        return {}
    }

    return ret
}

const signinApiUri = window.__DEP_PORTAL_DATA__
    ? window.__DEP_PORTAL_DATA__.signinApiUri || '/DepPortal/api/signin'
    : '/DepPortal/api/signin'
// creates a Redux wrapper around an async function, like for fetching data
// the first parameter is just a key for Redux, not an actual URI
export const authenticateUser = createAsyncThunk(
    'auth/authenticateUser',
    async ({ identifier, password, appName }) => {
        return await postAuth(signinApiUri, {
            identifier: identifier,
            password: password,
            appName: appName,
        })
    }
)

export const reauthenticateUser = createAsyncThunk(
    'auth/reauthenticateUser',
    async () => {
        let auth = JSON.parse(localStorage.getItem(storageKey) || `{}`)
        if (auth && auth.rememberMeCookieToken) {
            let additionalHeaders = {'X-JWT-Refresh': `Bearer ${auth.rememberMeCookieToken}`}
            return await postAuth(`${signinApiUri}?refresh=true`, {}, additionalHeaders)
        }
    }
)

export const signOut = createAsyncThunk('auth/signOut', ({ redirect }) => {
    localStorage.removeItem(storageKey)
    if (redirect) {
        window.location.href = redirect
    }
    return new Promise((resolve) => {
        resolve(true)
    })
})

// redux toolkit uses Immer immutables to allow muatti
const authResponseReducer = (state, action) => {
    const { success, status } = action.payload

    const auth = { ...action.payload }

    if (success) {
        auth.status = `success`
        auth.message = `Login successful.`
    } else if (status === 401) {
        auth.message = `The username and/or password entered were incorrect.`
    } else {
        auth.message = `An error occurred.`
    }
    auth.status = auth.status || `failure`
    saveAuth(auth)
    state.authResponse = auth
}

const signOutReducer = (state) => {
    const auth = {}
    saveAuth(auth)
    state.authResponse = auth
}

const authSlice = createSlice({
    name: 'auth',
    initialState: { authResponse: loadAuth() },
    reducers: {
        exampleReducerThatLogsTheStateAndParameters(state) {
            return state
        },
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [authenticateUser.fulfilled]: authResponseReducer,
        [authenticateUser.rejected]: authResponseReducer,
        [reauthenticateUser.fulfilled]: authResponseReducer,
        [reauthenticateUser.rejected]: authResponseReducer,        
        [signOut.fulfilled]: signOutReducer,
        [signOut.rejected]: signOutReducer,
    },
})

export const { exampleReducerThatLogsTheStateAndParameters } = authSlice.actions
export default authSlice.reducer
