import React from 'react'

export const ShowError = props => {
    if (props.error) {
        return (
            <div style={{ color: 'red', fontWeight: 'bold' }}>
                {JSON.stringify(props.error)}
            </div>
        )
    }
    return null
}
