import React from 'react'

export const SecurityQuestionAnswerRow = ({
    index,
    title,
    availableQuestions,
    pairStates,
    handlePairChange,
}) => {
    const selectedQuestions =
        (pairStates &&
            pairStates.map((pairState) => pairState.securityQuestionId)) ||
        []

    return (
        <fieldset>
            <legend>{title}</legend>

            <table>
                <tbody>
                    <tr>
                        <td>
                            <label
                                htmlFor={`securityQuestion${index}`}
                                className="label"
                            >
                                <strong>
                                    Question<span className="required">*</span>:
                                </strong>
                            </label>
                        </td>
                        <td>
                            <select
                                name={`securityQuestion${index}`}
                                id={`securityQuestion${index}`}
                                onChange={handlePairChange}
                                data-idx={index}
                                className="securityQuestionId"
                                value={pairStates[index].securityQuestionId}
                                defaultValue={0}
                                required
                            >
                                <option value="" key={0}>
                                    Select a Question
                                </option>
                                {availableQuestions.map((element, idx) => (
                                    <option
                                        key={idx + 1}
                                        value={element.id}
                                        disabled={selectedQuestions.includes(
                                            `${element.id}`
                                        )}
                                    >
                                        {element.question}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label
                                htmlFor={`securityAnswer${index}`}
                                className="label"
                            >
                                <strong>
                                    Answer<span className="required">*</span>:
                                </strong>
                            </label>
                        </td>
                        <td>
                            <input
                                id={`securityAnswer${index}`}
                                type="text"
                                name={`securityAnswer${index}`}
                                data-idx={index}
                                size="35"
                                required
                                maxLength="50"
                                className="securityAnswer"
                                onChange={handlePairChange}
                                value={pairStates[index].securityAnswer || ``}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    )
}
