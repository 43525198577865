import React, { useState } from 'react'
import {useLocation } from 'react-router-dom'
import queryString from 'query-string'

function SearchBox() {
    const location = useLocation()
    const { query } = queryString.parse(location.search)

    const [q, setQ] = useState(query || '')
    const contextRoot = window.__DEP_PORTAL_DATA__
        ? window.__DEP_PORTAL_DATA__.contextRoot || '/DepPortal'
        : '/DepPortal'
    // const [beginIndex, setBeginIndex] = useState(sourceBeginIndex)
    // const [endIndex, setEndIndex] = useState(sourceEndIndex)

    
    return (
        <div id="searchboxWrapper">
            <form id="searchForm" method="get" action={`${contextRoot}/search`} role="search">
                <label htmlFor="searchbox">Search the Site:</label>{' '}
                <input
                    id="searchbox"
                    type="search"
                    name="query"
                    size="15"
                    maxLength="250"
                    value={q || ''}
                    onChange={(e) => setQ(e.target.value)}
                />
            </form>
        </div>
    )
}

export default SearchBox
