import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    createAccount,
    fetchSecurityQuestions,
} from '../../features/account/accountSlice'
import { Redirect, Link } from 'react-router-dom'

const Field = ({ name, errors, children }) => {
    const error = errors[name]
    const classNames = error ? `formField invalid` : `formField`
    return (
        <div className={classNames}>
            {children}
            {error && <div className="validationMessage">{error}</div>}
        </div>
    )
}

function AccountRegistration() {
    const errors = useSelector((state) => state.account.errors) || {}
    const dispatch = useDispatch()

    const securityQuestions = useSelector(
        (state) => state.account.securityQuestions
    )
    const registrationSuccessful = useSelector(
        (state) => state.account.registrationSuccessful
    )

    const [emailAddress, setEmailAddress] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [phone, setPhone] = useState('')
    const [securityQuestion, setSecurityQuestion] = useState('')
    const [securityAnswer, setSecurityAnswer] = useState('')
    const [error, setError] = useState('') // eslint-disable-line no-unused-vars

    useEffect(
        () => {
            if (!securityQuestions) {
                dispatch(fetchSecurityQuestions())
            }
        },
        [ // eslint-disable-line react-hooks/exhaustive-deps
            /* 
        passing 0 dependencies causes it to run once only, 
        because empty array always equals empty array
    */
        ] // eslint-disable-line react-hooks/exhaustive-deps
    )

    if (registrationSuccessful) {
        return <Redirect to="/account/registrationComplete" />
    }

    if (!securityQuestions) {
        return `Loading...`
    }

    const handleForm = (event) => {
        event.preventDefault()
        submitAccountForCreation()
    }

    function submitAccountForCreation() {
        let accountRegistration = {
            email: emailAddress,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            zipCode: zipCode,
            phone: phone,
            securityQuestion: securityQuestion,
            securityAnswer: securityAnswer,
        }
        dispatch(createAccount(accountRegistration))
    }

    return (
        <div id="registrationForm" className="content-pane">
            <h1>Register</h1>
            <ul>
                <li>
                    If you've already registered with the DEP, then{' '}
                    <Link to={`/account/signin`}> sign in</Link>.
                </li>
                <li>
                    If you haven't received your verification e-mail, then we
                    can{' '}
                    <Link to={`/account/resendVerificationEmail`}>
                        re-send your verification email
                    </Link>
                    .
                </li>
                <li>
                    If you are a registered user but have forgotten your
                    password, then{' '}
                    <Link to={`/account/resetPassword`}>
                        reset your password
                    </Link>
                    .
                </li>
            </ul>

            <form onSubmit={handleForm}>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <span
                                    className="errorMessage"
                                    id="errorMessage"
                                >
                                    {error}
                                </span>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="email">
                                    E-mail Address
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="email" errors={errors}>
                                    <input
                                        name="email"
                                        id="emailAddress"
                                        type="email"
                                        size="50"
                                        maxLength="80"
                                        required
                                        onChange={(e) =>
                                            setEmailAddress(e.target.value)
                                        }
                                        value={emailAddress}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="firstName">
                                    First Name
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="firstName" errors={errors}>
                                    <input
                                        name="firstName"
                                        id="firstName"
                                        type="text"
                                        size="20"
                                        maxLength="50"
                                        required
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        value={firstName}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="middleName">Middle Name:</label>
                            </td>
                            <td>
                                <Field name="middleName" errors={errors}>
                                    <input
                                        name="middleName"
                                        id="middleName"
                                        type="text"
                                        size="20"
                                        maxLength="50"
                                        onChange={(e) =>
                                            setMiddleName(e.target.value)
                                        }
                                        value={middleName}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="lastName">
                                    Last Name<span className="required">*</span>
                                    :
                                </label>
                            </td>
                            <td>
                                <Field name="lastName" errors={errors}>
                                    <input
                                        name="lastName"
                                        id="lastName"
                                        type="text"
                                        size="30"
                                        maxLength="50"
                                        required
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        value={lastName}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="address1">
                                    Address (Line 1)
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="address1" errors={errors}>
                                    <input
                                        name="address1"
                                        id="address1"
                                        type="text"
                                        size="40"
                                        maxLength="50"
                                        required
                                        onChange={(e) =>
                                            setAddress1(e.target.value)
                                        }
                                        value={address1}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="address2">
                                    Address (Line 2):
                                </label>
                            </td>
                            <td>
                                <Field name="address2" errors={errors}>
                                    <input
                                        name="address2"
                                        id="address2"
                                        type="text"
                                        size="30"
                                        maxLength="50"
                                        onChange={(e) =>
                                            setAddress2(e.target.value)
                                        }
                                        value={address2}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="city">
                                    City<span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="city" errors={errors}>
                                    <input
                                        name="city"
                                        id="city"
                                        type="text"
                                        size="30"
                                        maxLength="50"
                                        required
                                        onChange={(e) =>
                                            setCity(e.target.value)
                                        }
                                        value={city}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="state">
                                    State<span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="state" errors={errors}>
                                    <input
                                        name="state"
                                        id="state"
                                        type="text"
                                        size="2"
                                        maxLength="2"
                                        required
                                        onChange={(e) =>
                                            setState(e.target.value)
                                        }
                                        value={state}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="zipCode">
                                    Zip Code<span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="zipCode" errors={errors}>
                                    <input
                                        name="zipCode"
                                        id="zipCode"
                                        type="text"
                                        size="10"
                                        maxLength="5"
                                        required
                                        onChange={(e) =>
                                            setZipCode(e.target.value)
                                        }
                                        value={zipCode}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="phone">
                                    Phone Number
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="phone" errors={errors}>
                                    <input
                                        name="phone"
                                        id="phone"
                                        type="tel"
                                        size="30"
                                        //pattern="\d{10}|(?:\d{3}-){2}\d{4}|\(\d{3}\)\d{3}-?\d{4}"
                                        maxLength="14"
                                        required
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                        value={phone}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                {' '}
                                In the event you forget your password, enter a
                                question and answer only known to you:
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="securityQuestion">
                                    Security Question
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="securityQuestion" errors={errors}>
                                    <select
                                        name="securityQuestion"
                                        id="securityQuestion"
                                        onChange={(e) =>
                                            setSecurityQuestion(e.target.value)
                                        }
                                        value={securityQuestion}
                                        required
                                    >
                                        <option
                                            disabled
                                            defaultValue="selected"
                                            value=""
                                        >
                                            Select a Question
                                        </option>
                                        {securityQuestions.map((element) => (
                                            <option
                                                key={element.id}
                                                value={element.id}
                                            >
                                                {element.question}
                                            </option>
                                        ))}
                                    </select>
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdLabel">
                                <label htmlFor="securityAnswer">
                                    Security Answer
                                    <span className="required">*</span>:
                                </label>
                            </td>
                            <td>
                                <Field name="securityAnswer" errors={errors}>
                                    <input
                                        name="securityAnswer"
                                        id="securityAnswer"
                                        type="text"
                                        required
                                        onChange={(e) =>
                                            setSecurityAnswer(e.target.value)
                                        }
                                        value={securityAnswer}
                                    />
                                </Field>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <input
                                    type="submit"
                                    value="Register"
                                    className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}

export default AccountRegistration
