import React from 'react'

function RegistrationComplete() {
    return (
        <div className="content-pane">
            <h1>Thanks!</h1>
            <p>Your registration is almost complete. We have sent you an e-mail in order to verify your
                e-mail address. Please follow the instructions in the e-mail to verify your account.</p>
        </div>
    )
}

export default RegistrationComplete