import React from 'react'

export const Field = ({ name, errors, children }) => {
    const error = errors[name]
    const classNames = error ? `formField invalid` : `formField`
    return (
        <div className={classNames}>
            {children}
            {error && <div className="validationMessage">{error}</div>}
        </div>
    )
}

export const InputText = ({ name, state, setState }) => {
    const { data, errors, meta } = state // eslint-disable-line no-unused-vars
    const field = meta.fields[name]
    return (
        <input
            name={name}
            type="text"
            size={field.size || 40}
            required={field.required}
            onChange={(e) => {
                const newData = { ...data, [name]: e.target.value }
                setState({ ...state, data: newData })
            }}
            value={data[name] || ``}
            {...field.inputOptions}
        />
    )
}

export const InputTel = ({ name, state, setState }) => {
    const { data, errors, meta } = state // eslint-disable-line no-unused-vars
    const field = meta.fields[name]
    return (
        <input
            name={name}
            type="tel"
            size={field.size || 40}
            required={field.required}
            onChange={(e) => {
                const newData = { ...data, [name]: e.target.value }
                setState({ ...state, data: newData })
            }}
            value={data[name] || ``}
            {...field.inputOptions}
        />
    )
}

export const InputTextArea = ({ name, state, setState }) => {
    const { data, errors, meta } = state // eslint-disable-line no-unused-vars
    const field = meta.fields[name]
    return (
        <textarea
            name={name}
            required={field.required}
            onChange={(e) => {
                const newData = { ...data, [name]: e.target.value }
                setState({ ...state, data: newData })
            }}
            {...field.inputOptions}
            value={data[name] || ``}
        />
    )
}

export const InputSelect = ({ name, state, setState, children }) => {
    const { data, errors, meta } = state // eslint-disable-line no-unused-vars
    const field = meta.fields[name]
    return (
        <select
            name={name}
            required={field.required}
            size={field.size || 40}
            onChange={(e) => {
                const newData = { ...data, [name]: e.target.value }
                setState({ ...state, data: newData })
            }}
            value={data[name] || ``}
            {...field.inputOptions}
        >
            {children}
        </select>
    )
}

export const InputRadio = ({ name, state, setState }) => {
    const { data, errors, meta } = state // eslint-disable-line no-unused-vars
    const field = meta.fields[name]

    const { options, ...extraOpts } = field.inputOptions

    const selectedValue = data[name] || ``

    return options.map((opt, index) => {
        const radioId = `${name}--${opt.key}`
        return (
            <span className="form-radio" key={radioId}>
                <input
                    type="radio"
                    name={name}
                    id={radioId}
                    required={field.required}
                    onChange={(e) => {
                        const newData = { ...data, [name]: e.target.value }
                        setState({ ...state, data: newData })
                    }}
                    checked={opt.key === selectedValue}
                    value={opt.key}
                    {...extraOpts}
                />
                <label htmlFor={radioId}>{opt.value}</label>
            </span>
        )
    })
}

export const InputRow = ({
    name,
    state,
    setState,
    InputComponent = InputText,
    children,
}) => {
    const { data, errors, meta } = state // eslint-disable-line no-unused-vars

    const field = meta.fields[name]

    return (
        <tr>
            <td className="tdLabel">
                <label htmlFor="email">
                    {field.title}
                    {field.required && <span className="required">*</span>}:
                </label>
            </td>
            <td>
                <Field name={name} errors={errors}>
                    <InputComponent
                        name={name}
                        type="text"
                        state={state}
                        setState={setState}
                    >
                        {children}
                    </InputComponent>
                </Field>
            </td>
        </tr>
    )
}

export const validate = (data, meta) => {
    const errors = {}
    Object.keys(meta.fields).map((name) => { // eslint-disable-line array-callback-return
        const field = meta.fields[name]
        if (field.validated !== false) {
            let value = (data[name] || ``)
            if(value && value.trim) {
                value = value.trim()
            }
            if (field.required && value === ``) {
                errors[name] = `${field.title} is required`
            }
        }
    })
    // TODO more validations based on metadata, as needed
    return errors
}

export const hasError = (errors) => Object.keys(errors).length > 0
