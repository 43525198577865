import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchSecurityQuestions } from '../../features/account/accountSlice'
import { putData } from '../../features/http/httpUtil'
import { SecurityQuestionAnswerRow } from '../../components/SecurityQuestionAnswerRow'

// const prepareQuestionAnswerPairs = questions => {
//     const result = Object.keys(questions).map((key, index) => {
//         const { id, question } = questions[key]
//         const answer = ``
//         return { id, question, answer }
//     })
//     result.sort((a, b) => a.question.localeCompare(b.question))
//     return result
// }

const ChangePinSecurityQuestions = () => {
    const [state, setState] = useState({
        data: { pairs: [{}, {}, {}, {}, {}] },
        errors: {},
    })
    const { data, errors } = state

    const history = useHistory()

    const availableQuestions = useSelector(
        state => state.account.securityQuestions
    )

    const dispatch = useDispatch()

    const availableQuestionsLoaded =
        availableQuestions && availableQuestions.length > 0

    useEffect(() => {
        dispatch(fetchSecurityQuestions())
        return () => {}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    
    const handlePairChange = e => {
        const newData = { ...data }
        newData.pairs[e.target.dataset.idx][e.target.className] = e.target.value
        setState({ data: newData, errors: errors })
    }

    const handlePinChange = e => {
        const newData = { ...data, pin: e.target.value }
        setState({ ...state, data: newData })
    }

    const savePinSecurityQuestions = (e) => {
        e.preventDefault()
        const request = { pin: data.pin, questionAnswerPairs: data.pairs }
        putData(`/api/pin/update-security`, { ...request }).then(result => {
            if (result.status && result.status !== 200) {
                setState({
                    ...state,
                    errors: { ...state.errors, saveError: result },
                })
            } else {
                history.push(`/account/details`)
            }
        })
    }

    if (availableQuestionsLoaded) {
        return (
            <div className="content-pane">
                <h1>Change PIN Security</h1>
                <p>
                    You can change the security questions and answers associated
                    with your PIN. For your security, we do not pre-populate
                    your existing security answers.
                </p>

                <p>
                    From the list provided, please select five personal security
                    questions that:
                </p>
                <ol>
                    <li>cannot be easily guessed or researched</li>
                    <li>don't change over time</li>
                    <li>
                        are memorable to you (i.e., others in your work place
                        would not be aware of or familiar with based on what
                        they know about you)
                    </li>
                    <li>are definitive or simple</li>
                </ol>
                <p>
                    These must be different from your account security question.
                </p>
                <div>
                    <label htmlFor={`maskedPin`} className="label">
                        <strong>
                            PIN<span className="required">*</span>:
                        </strong>
                    </label>
                    <input
                        type="password"
                        maxLength={4}
                        size={4}
                        required={true}
                        onChange={handlePinChange}
                        name="pin"
                        id="maskedPin"
                    />
                </div>
                <div>
                    {[...Array(5)].map((x, index) => (
                        <SecurityQuestionAnswerRow
                            key={index}
                            index={index}
                            availableQuestions={availableQuestions}
                            title={`Security Question ${index + 1}`}
                            pairStates={data.pairs}
                            handlePairChange={handlePairChange}
                        />
                    ))}
                </div>
                <div>
                    <input type="submit"
                        className="jq-button ui-button ui-widget ui-state-default ui-corner-all"
                        onClick={savePinSecurityQuestions}
                        value="Update PIN Security"
                    />
                </div>
            </div>
        )
    }
    return `Loading...`
}

export default ChangePinSecurityQuestions
