import React from 'react'
import AdminNav from './AdminNav'

function ManageDestinations() {
    /*
    todo: (images under ./wireframes/destinations/)
    display list of destinations from GET /destinations (01.png)
    user clicks a destination title and is presented with the details for that destination (02.png)
        - (low priority) display list of "similar destinations" from GET /destinations?relevant=<destination id> (also seen in 02.png)
        - user clicks "Edit," is presented with edit form (03.png)
        - user clicks "Delete," is prompted with modal (04.png)
    user clicks "Create New Destination" and is presented with create form (05.png)
        - "Parent" is populated with list of Portlets of type "Destination" from GET /portlets?type=destination (don't think this is currently filtered in prod)
        - (low priority) "additional search terms" is a semicolon-delimited list

    */
    return (
        <div className={`admin-content-pane`}>
            <AdminNav />
            <h1>Destinations</h1>
        
            Destination management.
        </div>
    )
}

export default ManageDestinations