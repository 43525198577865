import React from 'react'
import AdminNav from './AdminNav'

const DestinationHealthCheck = () => {

    return (
        <div className={`admin-content-pane`}>
            <AdminNav />
            <h1>Destination Health</h1>
            Check destination health
        </div>
    )
}

export default DestinationHealthCheck