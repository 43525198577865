import React from 'react'
import PinSet from './PinSet'

const ResetPin = () => (
    <PinSet
        title={`Reset PIN`}
        message={`You can reset your PIN by answering your security question below and re-entering your PIN. Remember to keep your PIN in a safe place.`}
    />
)

export default ResetPin
