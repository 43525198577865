import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'
const decodeURLRecursively = (url) => {
    if (url.indexOf('%') !== -1) {
        return decodeURLRecursively(decodeURIComponent(url))
    }

    return url
}
const ProtectedRoute = (props) => {
    const userInfoObject = useSelector(
        (state) => state.auth.authResponse.userInfoObject
    )
    const loc = useLocation()

    const postSigninRedirect =
        loc && loc.pathname
            ? decodeURLRecursively(`${loc.pathname}${loc.search}`)
            : ''
    const postSignInRedirectEnc = encodeURIComponent(postSigninRedirect)

    const redirectURI =
        `/account/signin` +
        (postSignInRedirectEnc && postSignInRedirectEnc !== ''
            ? `?internalRedirect=${postSignInRedirectEnc}`
            : '')

            
    const render = !userInfoObject
        ? () => <Redirect to={redirectURI} />
        : props.render

    return <Route {...props} render={render} />
}

export default ProtectedRoute
