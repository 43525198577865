import React from 'react'

import AdminNav from './AdminNav'
import UserSearchForm from './components/UserSearchForm'

const Admin = () => {
    return (
        <div className={`admin-content-pane`}>
            <AdminNav />
            <h2>Administrative Console</h2>
            Welcome to the administrative console. Please select an option
            above.

            <h2>User Search</h2>
            <UserSearchForm />
        
        </div>
    )
}

export default Admin
