import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchData } from '../http/httpUtil'

//const storageKey = `e29273eca8e441c085fde5e2f964d743-portal-portlet`
// const saveState = state =>    localStorage.setItem(storageKey, JSON.stringify(state))
// const loadState = () => JSON.parse(localStorage.getItem(storageKey) || `{}`)

// creates a Redux wrapper around an async function, like for fetching data
// the first parameter is just a key for Redux, not an actual URI
export const fetchPortlets = createAsyncThunk(
    'portlet/fetchPortlets',
    async () => {
        return await fetchData('/api/portlets/root')
    }
)

export const processPortletTree = rootPortlet => {
    const portletsByUri = {}
    if (rootPortlet) {
        const mapPortletsByUri = portlet => {
            portletsByUri[portlet.uri] = portlet
            portlet.children.forEach(mapPortletsByUri)
        }
        const compareDisplayOrder = (a, b) => {
            if (a.displayOrder > b.displayOrder) return 1
            if (a.displayOrder < b.displayOrder) return -1
            return 0
        }
        const sortByDisplayOrder = portlet => {            
            portlet.children.sort(compareDisplayOrder)
            portlet.destinations.sort(compareDisplayOrder)
            portlet.children.forEach(sortByDisplayOrder)        
        }

        mapPortletsByUri(rootPortlet)
        sortByDisplayOrder(rootPortlet)
    }
    return portletsByUri
}


const fetchPortletsResponseReducer = (state, action) => {
    const newState = { ...state, portlets: processPortletTree(action.payload) }
    //saveState(newState)
    return newState
}

const portletSlice = createSlice({
    name: 'portlet',
    initialState: {}, //loadState(),
    reducers: {},
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchPortlets.fulfilled]: fetchPortletsResponseReducer,
        [fetchPortlets.rejected]: fetchPortletsResponseReducer,
    },
})

export default portletSlice.reducer
